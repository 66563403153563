<template>
  <aw-password-change-form
    :form-id="formId"
    :user-hash="userHash"
    :is-modal="false"
    @success="onSuccess"
  >
    <template #heading>
      <lv-heading tag="h1" level="1" version2>
        {{ $awt('aw.password_change.title') }}
      </lv-heading>
    </template>
    <template #buttons="{ validator }">
      <div>
        <aw-form-button :model-value="validator" shrink styling="primary-dark">
          {{ $awt('aw.password_change.submit') }}
        </aw-form-button>
      </div>
    </template>
  </aw-password-change-form>
</template>

<script setup>
  import { navigateTo, useRoute, useNuxtApp, useId } from 'nuxt/app';
  import { LvHeading } from '~~/common/components/loginet-vue-shop/index.mjs';
  import AwFormButton from '~~/common/components/Form/AwFormButton';
  import AwPasswordChangeForm from '~~/shop/components/AwPasswordChangeForm.vue';

  defineOptions({
    name: 'PasswordChangePage',
  });

  const { $localePath } = useNuxtApp();

  const userHash = useRoute().params.hash;

  const formId = useId();

  function onSuccess () {
    navigateTo($localePath('/'));
  }
</script>
