<template>
  <div :class="$style.passwordChange">
    <slot name="heading" />
    <lv-paragraph>
      {{ $awt('aw.password_change.description') }}
    </lv-paragraph>
    <aw-form
      :id="formId"
      v-model="v$"
      :class="[
        $style.group,
        {
          [$style.isModal]: isModal,
        },
      ]"
      @success="onSubmit"
    >
      <div
        v-for="{inputObj,key,floatingLabel} in [
          {
            inputObj: newPasswordObj,
            key: 'newPassword',
            floatingLabel: 'aw.profile.manage.change_password_modal.new_password',
          },
          {
            inputObj: newPasswordAgainObj,
            key: 'newPasswordAgain',
            floatingLabel: 'aw.profile.manage.change_password_modal.new_password_again',
          },
        ]"
        :key="key"
        :class="$style.groupItem"
      >
        <aw-input-version2
          v-bind="inputObj.field"
          :type="pwdIconTypes[key]"
          :floating-label-text="$awt(floatingLabel)"
          has-label
          :widget-icon-attrs="pwdIconAttrs[key]"
        />
        <aw-minimal-error
          v-bind="inputObj.error"
        />
      </div>
      <slot name="buttons" :validator="v$" />
    </aw-form>
  </div>
</template>

<script setup>
  import { useVuelidate } from '@vuelidate/core';
  import { helpers } from '@vuelidate/validators';
  import { useNuxtApp } from 'nuxt/app';
  import { awRequired, awPasswordConfirm } from '~~/common/utils/form.js';
  import { generatePwdIcons } from '~~/common/utils/pwdFormUtils.js';
  import { setErrorNotification } from '~~/common/utils/helper';
  import { useNotificationStore } from '~~/common/stores/notification';
  import { useUserInterfaceStore } from '~~/common/stores/userInterface';
  import { LvParagraph } from '~~/common/components/loginet-vue-shop/index.mjs';
  import AwForm from '~~/common/components/Form/AwForm';
  import AwInputVersion2, { awInputV2Props } from '~~/common/components/Common/AwInputVersion2.vue';
  import AwMinimalError from '~~/common/components/Common/AwMinimalError.vue';

  const props = defineProps({
    formId: {
      type: String,
      required: true,
    },
    userHash: {
      type: String,
      required: true,
    },
    isModal: {
      type: Boolean,
      required: true,
    },
  });
  const emit = defineEmits(['success']);
  const { $api } = useNuxtApp();
  const { pwdIconTypes, pwdIconAttrs } = generatePwdIcons(['newPassword', 'newPasswordAgain']);
  const isLoading = ref(false);
  const newPassword = ref('');
  const newPasswordAgain = ref('');
  const v$ = useVuelidate(
    {
      newPassword: {
        required: awRequired(),
        regexCheck: helpers.withMessage(
          ({ $invalid }) => ($invalid
            ? pwdValidator.value.instruction
            : ''
          ),
          (password) => {
            return password?.match(pwdValidator.value?.validationString) != null;
          },
        ),
      },
      newPasswordAgain: {
        required: awRequired(),
        awPasswordConfirm: awPasswordConfirm((val) => {
          return val === newPassword.value;
        }),
      },
    },
    {
      newPassword,
      newPasswordAgain,
    },
  );
  const newPasswordObj = computed(() => awInputV2Props({
    validator: v$.value,
    validatorName: 'newPassword',
    model: newPassword,
    id: 'newPassword_error',
  }));
  const newPasswordAgainObj = computed(() => awInputV2Props({
    validator: v$.value,
    validatorName: 'newPasswordAgain',
    model: newPasswordAgain,
    id: 'newPasswordAgain_error',
  }));
  const pwdValidator = computed(() => {
    return useUserInterfaceStore().getValidator('password');
  });
  async function onSubmit () {
    const notificationStore = useNotificationStore();
    try {
      if (isLoading.value) {
        return;
      }
      isLoading.value = true;
      await $api.put(`/me/password_change/${props.userHash}`, {
        password: newPassword.value,
        confirmPassword: newPasswordAgain.value,
      });
      notificationStore.pushSuccess({
        manualClose: false,
        text: {
          titleToken: 'aw.global.success_password_change',
        },
      });
      emit('success');
    } catch (error) {
      notificationStore.pushError({
        manualClose: false,
        text: {
          titleToken: 'aw.global.error_password_change',
        },
      });
      setErrorNotification({ nuxtApp: useNuxtApp(), error });
    } finally {
      isLoading.value = false;
      newPassword.value = '';
      newPasswordAgain.value = '';
      v$.value.$reset();
    }
  }
</script>

<style module lang="scss" rel="stylesheet/scss">
.passwordChange {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  gap: 24px;
}

.group {
  display: inline-flex;
  align-items: start;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px;
  text-align: left;
  border-radius: 12px;
  gap: 20px;

  &.isModal {
    @include desktop-medium(min) {
      flex-direction: row;
      padding: 20px;
      border: 1px solid $color-dash-separator;
      background: $color-background-3;
    }
  }

  &:not(.isModal) {
    @include tablet(min) {
      flex-direction: row;
      padding: 20px;
      border: 1px solid $color-dash-separator;
      background: $color-background-3;
    }
  }
}

.groupItem {
  display: inline-flex;
  align-items: start;
  flex-direction: column;
}
</style>
