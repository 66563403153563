<template>
  <div :id="null" :class="$style.selectWrapper" :style="dynamicWidth ? {height: wrapperElementHeight + 'px'} : null">
    <div
      :class="[$style.select, {
        [$style.shrink]: shrink,
        [$style.dropdownSideTop]: dropdownSide === 'top',
        [$style.dynamicWidth]: dynamicWidth,
        [$style.dynamicWidthWithoutTranslateX]: dynamicWidthWithoutTranslateX,
        [$style.dynamicWidthOpened]: isOpened
      }]"
    >
      <template v-if="multiple">
        <span :id="idRemoveBtnDesc" style="display: none" v-text="$awt('aw.common.aw_select.btn_unselect_option')" />
        <ul :id="idRemoveBtnList" :class="['ulReset', $style.selectedOptions]">
          <li
            v-for="item in getOptions.filter(o => o.optionSelected)"
            :key="item[optionIdProperty].key || item[optionIdProperty].value"
          >
            <button
              type="button"
              :disabled="isDisabled || item.disabled"
              :aria-describedby="idRemoveBtnDesc"
              :class="[$style.selectedOptionsButton, $style[inputSize], { [$style.selectedOptionsButtonDisabled]: isDisabled || item.disabled }]"
              @click="toggleItem(item)"
            >
              <span v-text="getItemText(item)" />
              <lv-icon name="times-small-16" :size="16" />
            </button>
          </li>
        </ul>
      </template>
      <span class="awSrOnlyAbs">
        <span v-if="inlineLabelText" :id="idInlineLabelText" v-text="inlineLabelText" />
        <span
          v-if="!multiple"
          :id="idSelectedValueLabelText"
          v-text="(isAnyValueSelected && getSelectedOptionAria !== inlineLabelText) ? getSelectedOptionAria : ''"
        />
      </span>
      <div
        :id="comboboxId"
        ref="inputElement"
        :class="[
          $style[inputSize],
          $style.selectInput,
          {
            [$style.disabled]: isDisabled,
            [$style.active]: isOpened,
            [$style.empty]: isTitle(localValue),
            [$style.error]: hasError,
          }
        ]"
        data-form-input-select
        role="combobox"
        tabindex="0"
        :aria-describedby="rowUniqueId || null"
        :aria-labelledby="[
          outerLabelId ? outerLabelId : '',
          inlineLabelText ? idInlineLabelText : '',
          multiple ? idRemoveBtnList : idSelectedValueLabelText,
        ].filter(s=>s).join(' ')"
        :aria-disabled="isDisabled"
        :aria-expanded="isOpened.toString()"
        :aria-controls="`aw_listbox_${uuid}`"
        aria-autocomplete="none"
        aria-haspopup="listbox"
        :aria-activedescendant="isOpened ? (visualFocusId === null ? '' : generateListboxId(visualFocusId)) : null"
        @click="toggleOpenState({ limit: true })"
        @mouseup.stop="mouseUpBypassClick"
        @keydown="onKeyDown"
      >
        <span
          aria-hidden="true"
          :class="[$style.inputText, {[$style.titleLabel]: isTitle(localValue) && inlineLabelText}]"
        >
          <span
            v-if="isInlineLabelTextVisible"
            :class="[$style.fieldTitle, {[$style.fieldTitleCenter]: !isAnyValueSelected && showEmptySelectedValue}]"
            v-text="inlineLabelText"
          />
          <span
            v-if="!showEmptySelectedValue || (showEmptySelectedValue && isAnyValueSelected)"
            data-form-input-select-title
            :class="[{[$style.selectedOptionWithoutTitle]: !inlineLabelText, [$style.inputValueCenter]: centerValue }]"
            v-text="(isAnyValueSelected ? getSelectedOption : (!showEmptySelectedValue ? inlineLabelText : '')) || '\xA0'"
          />
        </span>
        <span
          aria-hidden="true"
          :class="[$style.chevronIcon, $style[inputSize], {[$style.active]: isOpened}]"
          data-form-input-select-chevron
        >
          <lv-icon v-if="dropdownIconAttrs.name" v-bind="dropdownIconAttrs" />
        </span>
      </div>
      <ul
        :id="`aw_listbox_${uuid}`"
        :style="{visibility: isOpened ? 'visible' : 'hidden'}"
        data-form-input-dropdown
        role="listbox"
        :class="[$style[inputSize], $style.selectList]"
      >
        <template v-if="dynamicWidth || isOpened">
          <li
            v-for="item in getOptions"
            :id="generateListboxId(item[optionIdProperty])"
            :key="item[optionIdProperty].key || item[optionIdProperty].value"
            :class="[
              'hideBasketOutsideClick',
              $style.selectListItem,
              {
                [$style.disabled]: item.disabled,
                [$style.active]: item.optionSelected,
                [$style.visualFocus]: item[optionIdProperty] === visualFocusId,
              },
            ]"
            role="option"
            :aria-disabled="item.disabled"
            :aria-selected="item.optionSelected.toString()"
            @click="toggleItem(item)"
            @keydown="onKeyDown"
          >
            <span
              class="awSrOnlyAbs"
              v-text="getItemTextAria(item) || ''"
            />
            <span
              aria-hidden="true"
              :class="['hideBasketOutsideClick', $style.itemText, {[$style.titleLabel]: isTitle(item)}]"
              v-text="`${getItemText(item)}` || '\xA0'"
            />
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>

<script>
  import { useId } from 'nuxt/app';
  import { nextTick, withModifiers } from 'vue';
  import { LvIcon } from '~~/common/components/loginet-vue-shop/index.mjs';
  import { createValidator } from '~~/common/utils/form.js';
  import {
    isOptEq,
    getPropOrObject,
    isEventIgnorable,
    isEventAutoOpens,
    isEventSearchAble,
    searchFunction,
    getNewIdByEventKey,
    getToggleAbleItem,
  } from '~~/common/utils/select.js';

  export default {
    name: 'AwSelectVersion2',
    components: { LvIcon },
    props: {
      rowUniqueId: {
        type: String,
        default: '',
      },
      outerLabelId: {
        type: String,
        default: '',
      },
      inlineLabelText: {
        type: String,
        default: '',
      },
      showEmptySelectedValue: {
        type: Boolean,
        default: false,
      },
      centerValue: {
        type: Boolean,
        default: false,
      },
      /**
       * @param {Array<{id: Number, name: String, disabled: Boolean}>} options
       */
      options: {
        type: [Object, Array],
        default: () => [],
      },
      /**
       * @param {(Array<{id: Number, name: String, disabled: Boolean}>, String, Number)} selectedOptions
       * if multiple is true selectedOptions have to be an array
       */
      selectedOptions: {
        type: [Object, Array, String, Number],
        required: true,
      },
      widgetAttrs: {
        type: Object,
        default () {
          return {};
        },
      },
      /**
       * @param {{name: String, size: Number}} dropdownIconAttrs
       */
      dropdownIconAttrs: {
        type: Object,
        default: Object,
      },
      /**
       * @param String size
       */
      inputSize: {
        type: String,
        default: 'lg',
      },
      /**
       * @param Boolean shrink
       */
      shrink: {
        type: Boolean,
        default: false,
      },
      dropdownSide: {
        type: String,
        default: 'bottom',
        validator: s => ['bottom', 'top'].includes(s),
      },
      disabled: {
        type: Boolean,
        default: null,
      },
      /**
       * @param Boolean multiple
       */
      multiple: {
        type: Boolean,
        default: true,
      },
      optionLabelProperty: {
        type: String,
        default: 'name',
      },
      optionIdProperty: {
        type: String,
        default: 'id',
      },
      // NOTE: this prop add dynamic width to wrapper according to the longest option, position absolute to the select wrapper element and add transform: translateX(-50%)
      dynamicWidth: {
        type: Boolean,
        default: false,
      },
      dynamicWidthWithoutTranslateX: {
        type: Boolean,
        default: false,
      },
      hasError: {
        type: Boolean,
        default: false,
      },
    },
    emits: [
      'update:selectedOptions',
      'select-enter',
      'blur',
      'open',
      'close',
    ],
    setup () {
      return {
        uuid: useId(),
      };
    },
    data () {
      return {
        isOpened: false,
        localValue: this.selectedOptions,
        visualFocusId: null,
        search: {
          idThrottleSearch: null,
          searchString: '',
        },
        idDebounceToggle: null,
        toggleLimit: 0,
        wrapperElementHeight: 0,
        bypassClickEvent: false,
      };
    },
    computed: {
      comboboxId () {
        return ((this.widgetAttrs && 'id' in this.widgetAttrs) ? this.widgetAttrs.id : this.$attrs.id) || null;
      },
      title () {
        return this.getOptions[0]?.[this.optionLabelProperty] || null;
      },
      idInlineLabelText () {
        return `aw_${this.uuid}_inline_label_text`;
      },
      idSelectedValueLabelText () {
        return `aw_${this.uuid}_selected_value_label_text`;
      },
      idRemoveBtnList () {
        return `aw_${this.uuid}_remove_btns`;
      },
      idRemoveBtnDesc () {
        return `aw_${this.uuid}_remove_btn`;
      },
      isAnyValueSelected () {
        return (Array.isArray(this.localValue) ? this.localValue[0] : this.localValue) !== '';
      },
      getSelectedOption () {
        return ([this.localValue || `${this.localValue}`]
          .flat()
          .map((item) => {
            return this.getItemText(item);
          })
          .join(', ')
        );
      },
      getSelectedOptionAria () {
        return ([this.localValue || `${this.localValue}`]
          .flat()
          .map((item) => {
            return this.getItemTextAria(item);
          })
          .join(', ')
        );
      },
      getOptions () {
        const arrOptions = Array.isArray(this.options) ? this.options : Object.values(this.options);
        return arrOptions.map(option => ({
          originalOption: option,
          [this.optionIdProperty]: option,
          [this.optionLabelProperty]: option,
          disabled: false,
          optionSelected: [this.localValue].flat().some((selOpt) => {
            return isOptEq(selOpt, option, this.optionIdProperty);
          }),
          ...option,
        }));
      },
      isDisabled () {
        if (typeof this.disabled === 'boolean') {
          return this.disabled;
        } else if (typeof this.widgetAttrs?.disabled === 'boolean') {
          return this.widgetAttrs.disabled;
        } else {
          return false;
        }
      },
      isInlineLabelTextVisible () {
        return (this.inlineLabelText && !this.isTitle(this.localValue) && !(this.centerValue && this.isAnyValueSelected));
      },
    },
    watch: {
      selectedOptions (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.localValue = newVal;
        }
      },
      visualFocusId (newVal) {
        if (newVal) {
          nextTick(() => {
            const e = document.getElementById(this.generateListboxId(newVal));
            if (e) {
              e.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
              });
            }
          });
        }
      },
    },
    mounted () {
      if (this.dynamicWidth) {
        requestAnimationFrame(() => {
          if (this.dynamicWidth && this.$refs?.inputElement) {
            this.wrapperElementHeight = this.$refs.inputElement.offsetHeight || 0;
          }
        });
      }
    },
    beforeUnmount () {
      document.removeEventListener('click', this.hide);
      document.removeEventListener('keyup', this.hide);
      if (this.isOpened) {
        this.isOpened = false;
      }
    },
    methods: {
      generateListboxId (id) {
        return `aw_listbox_${this.uuid}_${id}`;
      },
      onKeyDown ($event) {
        if (isEventIgnorable($event)) {
          return;
        }

        const isOriginallyOpened = this.isOpened;
        const isSearchAble = isEventSearchAble($event);

        if (!this.isOpened && isEventAutoOpens($event)) {
          this.toggleOpenState();
        }

        if (isSearchAble) {
          const searchOutput = searchFunction({
            $event,
            search: this.search,
            searchLabel: this.optionLabelProperty,
            searchId: this.optionIdProperty,
            searchOptions: this.getOptions,
            currentVisualFocusId: this.visualFocusId,
          });
          if (searchOutput) {
            this.visualFocusId = searchOutput.newVisualFocusId;
          }
        }

        const newId = getNewIdByEventKey({
          $event,
          currentVisualFocusId: this.visualFocusId,
          idKey: this.optionIdProperty,
          options: this.getOptions,
        });
        if (newId) {
          this.visualFocusId = newId.id;
        }

        if (isOriginallyOpened) {
          const item = getToggleAbleItem({
            $event,
            isSingle: !this.multiple,
            currentVisualFocusId: this.visualFocusId,
            idKey: this.optionIdProperty,
            options: this.getOptions,
          });
          if (item) {
            this.toggleItem(item);
            this.$emit('select-enter');
          }
          if ($event.key === 'Tab' && this.isOpened) {
            this.toggleOpenState();
          }
        }

        if (['ArrowUp', 'ArrowDown', 'Home', 'End', 'PageUp', 'PageDown', ' '].includes($event.key)) {
          $event.stopPropagation();
          $event.preventDefault();
        }
      },
      isTitle (item) {
        return Boolean(item?.title);
      },
      toggleOpenState ({ limit = false } = { limit: false }) {
        if (this.isDisabled) {
          return;
        }
        if (limit) {
          this.toggleLimit++;
          if (this.toggleLimit > 1) {
            return;
          }
          this.idDebounceToggle = setTimeout(() => {
            // too fast click assumes this is a dbclick
            this.toggleLimit = 0;
          }, 400);
        } else {
          clearTimeout(this.idDebounceToggle);
          this.toggleLimit = 0;
        }
        this.isOpened = !this.isOpened;
        document.removeEventListener('click', this.hide);
        document.removeEventListener('keyup', this.hide);
        if (this.isOpened) {
          nextTick(() => {
            document.addEventListener('click', this.hide);
            document.addEventListener('keyup', this.hide);
          });
        } else {
          this.$emit('blur');
        }
        this.$emit(this.isOpened ? 'open' : 'close');
      },
      hide (evt) {
        if (evt.type === 'click' && this.bypassClickEvent) {
          this.bypassClickEvent = false;
          return;
        }
        if (evt.type === 'click' || (evt.type === 'keyup' && evt.key === 'Escape')) {
          document.removeEventListener('click', this.hide);
          document.removeEventListener('keyup', this.hide);
          this.$emit('blur');
          this.isOpened = false;
        }
      },
      mouseUpBypassClick () {
        this.bypassClickEvent = true;
      },
      toggleItem (item) {
        if (item.isDisabled) {
          return;
        }
        if (this.multiple) {
          if (this.localValue.some(selectedItem => selectedItem[this.optionIdProperty] === item[this.optionIdProperty])) {
            this.localValue = this.localValue.filter(selectedItem => selectedItem[this.optionIdProperty] !== item[this.optionIdProperty]);
          } else {
            this.localValue.push(item);
          }
        } else {
          this.localValue = this.localValue === item[this.optionLabelProperty] ? '' : item;
          this.toggleOpenState();
        }
        const originalOptions = Array.isArray(this.localValue) ? this.localValue.map(option => getPropOrObject(option, 'originalOption')) : getPropOrObject(this.localValue, 'originalOption');
        this.$emit('update:selectedOptions', originalOptions);
      },
      getItemText (item) {
        return getPropOrObject(item, this.optionLabelProperty);
      },
      getItemTextAria (item) {
        return item?.ariaLabel || item?.['aria-label'] || this.getItemText(item);
      },
    },
  };
  export const awSelectV2Props = createValidator(
    function (
      { basicValidatorProps },
      { model } = {},
    ) {
      return {
        error: basicValidatorProps.error,
        field: {
          ...basicValidatorProps.field,
          selectedOptions: model.value,
          'onUpdate:selectedOptions': model === null ? null : withModifiers((newVal) => {
            model.value = newVal;
          }, ['trim']),
        },
      };
    },
  );
</script>

<style module lang="scss" rel="stylesheet/scss">
$aw-select-sizes: (
    "sm": (
        "padding": 8px 16px,
        "font-size": 12px,
        "line-height": 16px,
        "icon-size": 16px,
        "height": 32px
    ),
    "md": (
        "padding": 8px 16px,
        "font-size": 12px,
        "line-height": 16px,
        "icon-size": 16px,
        "height": 50px
    ),
    "lg": (
        "padding": 7px 15px,
        "font-size": 14px,
        "line-height": 20px,
        "icon-size": 16px,
        "height": 52px
    ),
    "lg-fat": (
        "padding": 8px 19px,
        "font-size": 14px,
        "line-height": 20px,
        "icon-size": 16px,
        "height": 54px
    ),
);
$aw-select-dynamic-width-paddings: (
    "md": (
        "padding": 8px 40px 8px 16px,
    ),
    "lg": (
        "padding": 11px 39px 11px 15px,
    ),
);
$select-shadow: 0 8px 24px $color-shadow-medium;

.selectedOptions {
  display: flex;
  flex-wrap: wrap;

  &Button {
    font-weight: bold;
    display: flex;
    align-items: center;
    margin: 2px;
    padding: 2px;
    color: $color-white;
    border-radius: 4px;
    background: $color-brand-secondary;

    &:not(&Disabled):is(:hover, :focus, :active) {
      background: $color-brand-secondary-active;
    }

    &Disabled {
      cursor: not-allowed;
      background: $color-text-tertiary;
    }
  }
}

.select {
  position: relative;
  cursor: pointer;

  &Wrapper {
    position: relative;
  }

  &.shrink {
    display: inline-block;
  }

  .selectInput {
    font-family: $secondary-font;
    font-weight: 900;
    display: flex;
    justify-content: space-between;
    width: 100%;
    color: $color-text-primary;
    border: 1px solid $color-border;
    border-radius: 12px;
    background-color: $color-background-2;

    &.empty,
    &.active {
      background-color: $color-background-4;
    }

    &.active {
      border-color: $color-border-active;

      .chevronIcon svg {
        transform: rotate(180deg);
      }
    }

    &.disabled {
      cursor: not-allowed;
      opacity: 1;
      border-color: $color-border-disabled;
      background-color: $color-background-2 !important;

      .chevronIcon,
      .inputText,
      .fieldTitle {
        color: $color-text-tertiary;
      }
    }

    &.error {
      border-color: $color-error-v2-border;
      background-color: $color-error-v2-background;

      .inputText,
      .fieldTitle {
        color: $color-error-v2-text !important;
      }

      .chevronIcon {
        color: $color-error-v2-border;
      }
    }

    .fieldTitle {
      @include font(500, 12px, 16px);
      color: $color-text-secondary;

      &Center {
        line-height: 32px;
      }
    }

    .inputText {
      display: flex;
      overflow: hidden;
      flex-direction: column;
      justify-content: center;
      white-space: nowrap;

      &.titleLabel {
        font-weight: 500;
        align-self: center;
        color: $color-text-secondary;
      }

      .selectedOptionWithoutTitle {
        display: flex;
        align-items: center;
        height: 100%;
      }
    }

    .inputValueCenter {
      line-height: 32px;
    }

    .chevronIcon {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      justify-content: center;
      margin-left: auto;
      padding-left: 8px;

      .sm {
        height: 16px;
      }

      .md {
        height: 36px;
      }
    }

  }

  .selectList {
    position: absolute;
    z-index: 1000;
    left: 0;
    overflow-y: auto;
    min-width: 100%;
    max-width: 100%;
    max-height: 208px;
    margin: 8px 0 0;
    padding: 8px;
    list-style-type: none;
    border-radius: 12px;
    background-color: $color-white;
    box-shadow: $select-shadow;

    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    .selectListItem {
      font-weight: 500;
      line-height: 20px;
      position: relative;
      display: block;
      width: max-content;
      min-width: 100%;
      max-width: 100%;
      text-align: left;
      border: 0;
      border-radius: 8px;
      background-color: $color-white;

      &:first-child {
        margin-top: 2px;
      }

      &:not(:last-child) {
        margin-bottom: 2px;
      }

      &:hover,
      &.visualFocus {
        color: $color-text-primary;
        background-color: $color-background-3;

        &:active {
          background-color: $color-background-2;
        }

        &.active {
          color: $color-text-marigold;
          background-color: $color-marigold--12;
        }
      }

      &.active {
        background-color: $color-marigold--12;

        .itemText {
          overflow: hidden;
          text-align: left;
          //white-space: nowrap;
          text-overflow: clip;
          color: $color-text-marigold !important;
        }
      }

      &.disabled {
        cursor: not-allowed;
        color: $color-shuttle-gray;
        background-color: $color-seashell;
      }

      .itemText {
        font-weight: 500;
        text-align: left;

        &.titleLabel {
          color: $color-silver-chalice
        }
      }
    }
  }

  @each $aw-select-size-name, $aw-select-size in $aw-select-sizes {
    .selectedOptionsButton,
    .selectInput {
      &.#{$aw-select-size-name} {
        font-size: map-get($aw-select-size, "font-size");
      }
    }

    .selectInput.#{$aw-select-size-name} {
      font-size: map-get($aw-select-size, "font-size");
      line-height: map-get($aw-select-size, "line-height");
      height: map-get($aw-select-size, "height");
      padding: map-get($aw-select-size, "padding");

      .chevronIcon {
        font-size: map-get($aw-select-size, "icon-size");
      }
    }

    .selectList.#{$aw-select-size-name} {
      .selectListItem {
        padding: map-get($aw-select-size, "padding");

        .itemText {
          font-size: map-get($aw-select-size, "font-size");
          line-height: map-get($aw-select-size, "line-height");
          display: block;
          min-height: map-get($aw-select-size, "line-height");
        }
      }
    }
  }

  &.dropdownSideTop {
    .selectInput.active {
      border-radius: 0 0 8px 8px;
    }

    .selectList {
      transform: translateY(calc(-100% - 56px));
      border-radius: 8px 8px 0 0;
    }
  }

  &.dynamicWidth {
    position: absolute;
    display: grid;
    grid-template-columns: max-content;
    transform: translateX(-50%);

    &Opened {
      z-index: 1;
    }

    .selectList {
      position: relative;
      max-width: max-content;
    }

    @each $aw-select-size-name, $aw-select-size in $aw-select-dynamic-width-paddings {

      .selectList.#{$aw-select-size-name} {
        .selectListItem {
          padding: map-get($aw-select-size, "padding");
        }
      }
    }

    &WithoutTranslateX {
      transform: unset;
    }
  }
}
</style>
