<template>
  <div>
    <aw-input-version2
      v-model="localValue"
      :has-success="hasSuccess"
      :has-error="hasError"
      :is-focused="isFocused"
      :widget-attrs="{ maxlength, ...widgetAttrs }"
      :is-invalid="isInvalid"
      :is-required="isRequired"
      :row-unique-id="rowUniqueId"
      @update:model-value="onChange"
      @blur="$emit('blur')"
    >
      <template #prefix>
        {{ prefix }}
      </template>
    </aw-input-version2>
  </div>
</template>

<script>
  import { nextTick } from 'vue';
  import AwInputVersion2 from '~~/common/components/Common/AwInputVersion2';
  import { useUserInterfaceStore } from '~~/common/stores/userInterface';

  export default {
    name: 'AwPhoneInputVersion2',
    components: {
      AwInputVersion2,
    },
    props: {
      modelValue: {
        type: Object,
        required: true,
        validator: val => val?.model?.length === 3,
      },
      gutter: {
        type: String,
        required: false,
        default: 'sm',
      },
      isExpand: {
        type: Boolean,
        required: false,
        default: false,
      },
      hasError: {
        type: Boolean,
        default: false,
      },
      hasSuccess: {
        type: Boolean,
        default: false,
      },
      isFocused: {
        type: Boolean,
        default: false,
      },
      widgetAttrs: {
        type: Object,
        default: () => ({}),
      },
      isRequired: {
        type: Boolean,
        default: false,
      },
      isInvalid: {
        type: Boolean,
        default: false,
      },
      rowUniqueId: {
        type: String,
        required: true,
      },
    },
    emits: [
      'update:modelValue',
      'validation',
      'blur',
    ],
    data () {
      return {
        localValue: null,
      };
    },
    computed: {
      prefix () {
        return this.modelValue?.model?.[0] || '';
      },
      formats () {
        return useUserInterfaceStore().getValidator('phone_number').metaData;
      },
      countryCallingCodes () {
        return Object.keys(this.formats);
      },
      areaCodes () {
        return this.formats[this.modelValue.model[0]]?.areaCodes || [];
      },
      maxlength () {
        return (this.formats[this.modelValue.model[0]]?.maxlength || 0) + 2;
      },
      isValid () {
        return useUserInterfaceStore().phoneValidator(this.modelValue.model);
      },
    },
    watch: {
      modelValue: {
        handler () {
          this.modelToLocalValue();
        },
        deep: true,
      },
    },
    mounted () {
      if (!this.countryCallingCodes.includes(this.modelValue.model[0])) {
        // eslint-disable-next-line vue/no-mutating-props
        this.modelValue.model[0] = this.countryCallingCodes[0];
      }
      nextTick(() => {
        this.modelToLocalValue();
      });
    },
    methods: {
      localValueToModel () {
        const newValue = [];
        newValue[0] = this.modelValue.model[0];
        newValue[1] = this.localValue?.slice(0, 2) || '';
        newValue[2] = this.localValue?.slice(2) || '';
        // eslint-disable-next-line vue/no-mutating-props
        this.modelValue.model = newValue;
      },
      modelToLocalValue () {
        this.localValue = `${this.modelValue.model[1]}${this.modelValue.model[2]}`;
      },
      onChange () {
        this.localValueToModel();
        this.$emit('update:modelValue', this.modelValue);
        this.$emit('validation', this.isValid);
      },
    },
  };
</script>
<style module lang="scss" rel="stylesheet/scss">
</style>
