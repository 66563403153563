import { useNuxtApp } from 'nuxt/app';
import { useUserInterfaceStore } from '~~/common/stores/userInterface';
import { email, between, helpers, minValue, requiredIf } from '@vuelidate/validators';

export const phonePrefix = computed(() => {
  return Object.keys(
    useUserInterfaceStore().getValidator('phone_number').metaData,
  )?.[0];
});

export function awRequiredOptional (validatorFunction) {
  const { $awt } = useNuxtApp();
  return helpers.withMessage(
    ({ $invalid }) => ($invalid
      ? $awt('aw.common.form.validation.required_optional')
      : ''
    ),
    validatorFunction,
  );
}

export function awRequiredCheckbox () {
  const { $awt } = useNuxtApp();
  return helpers.withMessage(
    ({ $invalid }) => ($invalid
      ? $awt('aw.common.form.validation.required')
      : ''
    ),
    val => val === true,
  );
}

export function awRequired ({ ifFun } = {}) {
  const { $awt } = useNuxtApp();
  return helpers.withMessage(
    ({ $invalid }) => ($invalid
      ? $awt('aw.common.form.validation.required')
      : ''
    ),
    requiredIf(ifFun || (() => true)),
  );
}

export function awEmail () {
  const { $awt } = useNuxtApp();
  return helpers.withMessage(
    ({ $invalid }) => ($invalid
      ? $awt('aw.common.form.validation.email')
      : ''
    ),
    email,
  );
}

export function awMinValue (value, token) {
  const { $awt } = useNuxtApp();
  return helpers.withMessage(
    ({ $invalid }) => ($invalid
      ? $awt(token)
      : ''
    ),
    minValue(value),
  );
}

export function awBetween (minValue, maxValue, token) {
  const { $awt } = useNuxtApp();
  return helpers.withMessage(
    ({ $invalid }) => ($invalid
      ? $awt(token)
      : ''
    ),
    between(minValue, maxValue),
  );
}

export function awTajNumberValidator () {
  const { $awt } = useNuxtApp();
  return helpers.withMessage(
    ({ $invalid }) => ($invalid
      ? $awt('aw.common.form.validation.taj_number_invalid')
      : ''
    ),// - tajszám: 9 db szám karakter, szigorúan string -ként.
    val => /^[0-9]{9}$/.test(val),
  );
}

export function awTaxNumberValidator () {
  const { $awt } = useNuxtApp();
  return helpers.withMessage(
    ({ $invalid }) => ($invalid
      ? $awt('aw.common.form.validation.tax_number_invalid')
      : ''
    ),//  - adószám: egy nyolcas, és 9 db számjegy, szintén stringként.
    val => /8[0-9]{9}$/.test(val),
  );
}

export function awPasswordConfirm (comparisonFunction) {
  const { $awt } = useNuxtApp();
  return helpers.withMessage(
    ({ $invalid }) => ($invalid
      ? $awt('aw.common.form.validation.same_password')
      : ''
    ),
    comparisonFunction,
  );
}

export function awValidOptionalMobile () {
  const { $awt } = useNuxtApp();
  return helpers.withMessage(
    ({ $invalid }) => ($invalid
      ? $awt('aw.common.form.validation.phone_invalid')
      : ''
    ),
    (val) => {
      return (val
        ? useUserInterfaceStore().phoneValidator([
          phonePrefix.value,
          val?.slice(0, 2) || '',
          val?.slice(2) || '',
        ])
        : true
      );
    },
  );
}

export function awIdNotEqualWith (id) {
  const { $awt } = useNuxtApp();
  return helpers.withMessage(
    ({ $invalid }) => ($invalid
      ? $awt('aw.common.form.validation.required')
      : ''
    ),
    (val) => val?.id && val.id !== id,
  );
}

export function createValidator (fun) {
  return function ({
    validator,
    validatorName,
    model,
    id,
  } = {}) {
    const namedValidator = validator?.[validatorName];
    const { $logger } = useNuxtApp();
    if (typeof validator !== 'object') {
      $logger.error(new TypeError(`typeof validator should be object instead received ${typeof validator}`));
    }
    if (typeof namedValidator !== 'object') {
      $logger.error(new TypeError(`typeof namedValidator should be object instead received ${typeof namedValidator}`));
    }
    if (typeof validatorName !== 'string') {
      $logger.error(new TypeError(`typeof validatorName should be string or null instead received ${typeof validatorName}`));
    }
    if (model?.value === undefined && model !== null) {
      $logger.error(new TypeError(`typeof model.value should not be undefined or model should be null instead received a model which is ${typeof model}<${typeof model?.value}>`));
    }
    if (typeof id !== 'string' && id !== null) {
      $logger.error(new TypeError(`typeof id should be string or null instead received ${typeof id}`));
    }
    const result = fun(
      {
        namedValidator,
        basicValidatorProps: getBasicValidatorProps({
          id,
          namedValidator,
        }),
      },
      ...arguments,
    );
    if (typeof result?.field !== 'object' || typeof result?.error !== 'object') {
      $logger.error(new TypeError('result should have error and field objects'));
    }
    return result;
  };
}

function getBasicValidatorProps ({
  id,
  namedValidator,
}) {
  return {
    field: {
      rowUniqueId: id,
      hasError: Boolean(namedValidator.$errors.length),
      hasSuccess: namedValidator.$dirty && !namedValidator.$invalid,
      isInvalid: namedValidator.$invalid,
      isRequired: Boolean(namedValidator.required),
      onBlur: () => {
        setTimeout(() => {
          namedValidator.$touch();
        }, 200);
      },
    },
    error: {
      id,
      validator: namedValidator,
    },
  };
}
