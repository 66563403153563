<template>
  <div :class="$style.forgottenPassword">
    <lv-heading tag="h1" level="1" version2>
      {{ $awt('aw.forgotten_password.title') }}
    </lv-heading>
    <aw-form v-model="v$" :class="$style.group" @success="onSubmit">
      <div>
        <aw-input-version2
          v-bind="emailObj.field"
          :class="$style.emailInput"
          :floating-label-text="$awt('aw.common.header.user.login_and_registration_modal.username')"
          has-label
          :widget-icon-attrs="{
            size: 24,
            name: 'envelope-24',
            position: 'after',
          }"
        />
        <aw-minimal-error
          v-bind="emailObj.error"
        />
      </div>
      <aw-form-button v-model="v$" shrink styling="primary-dark">
        {{ $awt('aw.forgotten_password.submit') }}
      </aw-form-button>
    </aw-form>
  </div>
</template>

<script setup>
  import { ref, computed } from 'vue';
  import { useVuelidate } from '@vuelidate/core';
  import { navigateTo, useNuxtApp } from 'nuxt/app';
  import { awRequired, awEmail } from '~~/common/utils/form.js';
  import { setErrorNotification } from '~~/common/utils/helper';
  import { useNotificationStore } from '~~/common/stores/notification';
  import { LvHeading } from '~~/common/components/loginet-vue-shop/index.mjs';
  import AwForm from '~~/common/components/Form/AwForm.vue';
  import AwFormButton from '~~/common/components/Form/AwFormButton.vue';
  import AwInputVersion2, { awInputV2Props } from '~~/common/components/Common/AwInputVersion2.vue';
  import AwMinimalError from '~~/common/components/Common/AwMinimalError.vue';

  const { $awt, $localePath, $api } = useNuxtApp();
  const isLoading = ref(false);
  const email = ref('');

  const v$ = useVuelidate(
    {
      email: {
        required: awRequired(),
        email: awEmail(),
      },
    },
    {
      email,
    },
  );

  const emailObj = computed(() => {
    return awInputV2Props({
      validator: v$.value,
      validatorName: 'email',
      model: email,
      id: 'email_error',
    });
  });

  async function onSubmit () {
    try {
      if (isLoading.value) {
        return;
      }
      isLoading.value = true;
      await $api.put('/me/initiate_password_change', {
        email: email.value,
      });

      await navigateTo($localePath('/'));
      const notificationStore = useNotificationStore();
      notificationStore.pushSuccess({
        manualClose: false,
        text: {
          titleToken: 'aw.forgotten_password.success_message',
        },
      });
    } catch (error) {
      setErrorNotification({ nuxtApp: useNuxtApp(), error });
    } finally {
      isLoading.value = false;
    }
  }
</script>

<style module lang="scss" rel="stylesheet/scss">
.emailInput {
  max-width: 296px;
}

.forgottenPassword {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding-bottom: 20px;
  gap: 24px;

  @include tablet(min) {
    padding-bottom: 60px;
  }
}

.group {
  display: inline-flex;
  align-items: start;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 8px;
  border-radius: 12px;
  gap: 20px;

  @include tablet(min) {
    flex-direction: row;
    padding: 20px;
    border: 1px solid $color-dash-separator;
    background: $color-background-3;
  }
}
</style>
