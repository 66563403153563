<template>
  <div>
    <div
      :class="[
        $style.formContainer,
        {
          [$style.formContainerError]: hasError,
        },
      ]"
    >
      <div :class="[{[$style.formLabel]: $slots.beforeLabel || (label && labelPosition === 'before')}, {[$style.formLabelDisabled]: isDisabled, [$style.formLabelBold]: checked}]">
        <label v-if="$slots.beforeLabel || (label && labelPosition === 'before')" :for="inputId">
          <slot v-if="$slots.beforeLabel" name="beforeLabel" />
          <template v-else>
            {{ label }}
          </template>
        </label>
      </div>
      <label :class="$style.formToggle">
        <input
          v-bind="{
            ...awAttrsNoStyleNoClassNoListeners,
            ...widgetAttrs,
          }"
          :id="inputId"
          type="checkbox"
          :disabled="isDisabled"
          :checked="checked"
          :value="value"
          @change="onChange"
          @blur="$emit('blur', $event)"
        >
        <div :class="$style.formSlider" />
      </label>
      <div :class="[{[$style.formLabel]: $slots.label || (label && labelPosition === 'after')}, {[$style.formLabelDisabled]: isDisabled}]">
        <label v-if="$slots.label || (label && labelPosition === 'after')" :for="inputId">
          <slot v-if="$slots.label" name="label" />
          <template v-else>
            {{ label }}
          </template>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
  import { useId } from 'nuxt/app';
  import { createValidator } from '~~/common/utils/form.js';
  import awListenersMixin from '~~/common/mixins/awListenersMixin.js';

  export default {
    name: 'AwToggleVersion2',
    mixins: [awListenersMixin],
    props: {
      modelValue: {
        type: [Boolean, String],
        default: '',
      },
      value: {
        type: [String, Number],
        default: '',
      },
      disabled: {
        type: Boolean,
        default: null,
      },
      label: {
        type: String,
        default: '',
      },
      hasError: {
        type: Boolean,
        default: false,
      },
      labelPosition: {
        type: String,
        validator: s => ['before', 'after'].includes(s),
        default: 'after',
      },
      widgetAttrs: {
        type: Object,
        default: null,
      },
    },
    emits: [
      'update:modelValue',
      'blur',
    ],
    setup (props, { emit }) {
      const checked = computed(() => {
        return props.value ? props.value === props.modelValue : props.modelValue;
      });
      return {
        inputId: useId(),
        checked,
        isDisabled: computed(() => {
          if (typeof props.disabled === 'boolean') {
            return props.disabled;
          } else if (typeof props.widgetAttrs?.disabled === 'boolean') {
            return props.widgetAttrs.disabled;
          } else {
            return false;
          }
        }),
        onChange ($event) {
          if (props.value) {
            emit('update:modelValue', checked.value ? '' : $event.target.value);
          } else {
            emit('update:modelValue', $event.target.checked);
          }
        },
      };
    },
  };

  export const awToggleV2Props = createValidator(
    function (
      { basicValidatorProps },
      { model } = {},
    ) {
      return {
        error: basicValidatorProps.error,
        field: {
          ...basicValidatorProps.field,
          modelValue: model.value,
          'onUpdate:modelValue': (newVal) => {
            model.value = newVal;
          },
        },
      };
    },
  );
</script>

<style module lang="scss" rel="stylesheet/scss">
.form {
  &Container {
    @include font(500, 14px, 20px);
    display: flex;
    align-items: center;

    &Error {
      .formSlider {
        border-color: $color-error-v2-border !important;
      }

      .formLabel label {
        color: $color-error-v2-text !important;
      }
    }
  }

  &Toggle {
    position: relative;
    display: inline-block;
    width: 48px;
    height: 24px;
    vertical-align: middle;

    input {
      appearance: none;
      width: 0;
      height: 0;
    }
  }

  &Label {
    margin-left: 8px;
    user-select: none;

    label {
      cursor: pointer;
    }

    &Disabled {
      label {
        cursor: not-allowed;
        color: $color-text-tertiary;
      }
    }
  }

  &Slider {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    cursor: pointer;
    transition: .4s;
    border: 1px solid $color-border;
    border-radius: 20px;
    background-color: $color-background-3;

    &::before {
      position: absolute;
      bottom: 2px;
      left: 2px;
      width: 18px;
      height: 18px;
      content: "";
      transition: .4s;
      border-radius: 50%;
      background-color: $color-dash-separator;
    }
  }

  input:checked + &Slider {
    border-color: $color-sage-450;
    background-color: $color-white;

    &::before {
      background-color: $color-sage-450;
    }
  }

  input[disabled] + &Slider {
    cursor: not-allowed;

    &::before {
      cursor: not-allowed;
    }
  }

  input[disabled][checked] + &Slider {
    border-color: #E9E5D5;
    background-color: $color-background-3;

    &::before {
      background-color: #E9E5D5;
    }
  }

  input:checked + &Slider::before {
    transform: translateX(24px);
  }
}
</style>
