<template>
  <div :class="[$style.mainContainer, {[$style.disabled]: isDisabled}]">
    <label
      v-bind="labelAttrs"
      :class="[$style.mainLabel, $style[`align-items-${alignItems}`], {[$style.mainLabelHasError]: hasError, [$style.shrink]: shrink || (!label && !$slots.label?.()?.[0]?.children?.length)}]"
    >
      <input
        type="checkbox"
        v-bind="widgetAttrs"
        :disabled="isDisabled"
        :checked="checked"
        :value="value"
        :aria-describedby="rowUniqueId ? rowUniqueId : null"
        @change="onChange"
        @blur="$emit('blur', $event)"
      >
      <lv-icon
        :key="`icon-${checked}`"
        :class="[$style.icon]"
        :name="checked ? checkedIcon : uncheckedIcon"
        :size="24"
        :fill="false"
      />
      <span
        v-if="hasLabel"
        :class="[$style.labelText, $style[labelSize], {[$style.labelHide]: !label && !$slots.label?.()?.[0]?.children?.length, [$style.desktopLabel]:showLabelInDesktop}]"
      >
        <slot v-if="$slots.label?.()?.[0]?.children?.length" name="label" />
        <template v-else-if="label">
          <span v-html="label" />
        </template>
      </span>
    </label>
  </div>
</template>

<script>
  import { LvIcon } from '~~/common/components/loginet-vue-shop/index.mjs';
  import { createValidator } from '~~/common/utils/form.js';

  export default {
    name: 'AwCheckboxVersion2',
    components: {
      LvIcon,
    },
    props: {
      modelValue: {
        type: [Array, Object, String, Number, Boolean],
        default: () => [],
      },
      value: {
        type: [Object, String, Number],
        default: () => {
        },
      },
      disabled: {
        type: Boolean,
        default: null,
      },
      label: {
        type: String,
        default: '',
      },
      labelSize: {
        type: String,
        default: 'lg',
        validator: value => ['xs', 'md', 'lg'].includes(value),
      },
      alignItems: {
        type: String,
        default: 'center',
        validator: alignItems => ['start', 'center', 'end'].includes(alignItems),
      },
      radioInputIconDesign: {
        type: Boolean,
        default: false,
      },
      shrink: {
        type: Boolean,
        default: false,
      },
      rowUniqueId: {
        type: String,
        default: '',
      },
      labelAttrs: {
        type: Object,
        default () {
          return {};
        },
      },
      widgetAttrs: {
        type: Object,
        default () {
          return {};
        },
      },
      hasLabel: {
        type: Boolean,
        default: false,
      },
      hasError: {
        type: Boolean,
        default: false,
      },
      showLabelInDesktop: {
        type: Boolean,
        default: false,
      },
    },
    emits: [
      'update:modelValue',
      'blur',
    ],
    computed: {
      isDisabled () {
        if (typeof this.disabled === 'boolean') {
          return this.disabled;
        } else if (typeof this.widgetAttrs?.disabled === 'boolean') {
          return this.widgetAttrs.disabled;
        } else {
          return false;
        }
      },
      checked () {
        let checked = this.modelValue;
        if (this.value) {
          if (Array.isArray(this.modelValue)) {
            // array items is object?
            if (typeof this.value === 'object') {
              checked = this.modelValue.some(item => JSON.stringify(item) === JSON.stringify(this.value));
            } else {
              checked = this.modelValue.includes(this.value);
            }
          } else {
            checked = this.value === this.modelValue;
          }
        }
        return checked;
      },
      checkedIcon () {
        return (this.radioInputIconDesign)
          ? (this.isDisabled ? 'radio-checked-disabled-v2-24' : 'radio-checked-v2-24')
          : (this.isDisabled ? 'checkbox-checked-disabled-v2-24' : (this.hasError ? 'checkbox-checked-error-v2-24' : 'checkbox-checked-v2-24'));
      },
      uncheckedIcon () {
        return (this.radioInputIconDesign)
          ? (this.isDisabled ? 'radio-unchecked-disabled-v2-24' : 'radio-unchecked-v2-24')
          : (this.isDisabled ? 'checkbox-unchecked-disabled-v2-24' : (this.hasError ? 'checkbox-unchecked-error-v2-24' : 'checkbox-unchecked-v2-24'));
      },
    },
    methods: {
      onChange ($event) {
        if (this.value) {
          // check modelValue is array
          if (Array.isArray(this.modelValue)) {
            // create new array instance from prop array
            const modelArray = [].concat(this.modelValue);
            const index = modelArray.findIndex(item => JSON.stringify(item) === JSON.stringify(this.value));
            if (index !== -1) {
              modelArray.splice(index, 1);
            } else {
              modelArray.push(this.value);
            }
            this.$emit('update:modelValue', modelArray);
          } else {
            this.checked ? this.$emit('update:modelValue', '') : this.$emit('update:modelValue', this.value);
          }
        } else {
          this.$emit('update:modelValue', $event.target.checked);
        }
      },
    },
  };

  export const awCheckboxV2Props = createValidator(
    function (
      { basicValidatorProps },
      { model } = {},
    ) {
      return {
        error: basicValidatorProps.error,
        field: {
          ...basicValidatorProps.field,
          modelValue: model.value,
          'onUpdate:modelValue': (newVal) => {
            model.value = newVal;
          },
        },
      };
    },
  );
</script>

<style module lang="scss" rel="stylesheet/scss">
.mainContainer {
  .mainLabel {
    display: flex;
    cursor: pointer;
    user-select: none;

    &.shrink {
      display: inline-flex;
    }

    &.align-items-start {
      align-items: flex-start;
    }

    &.align-items-center {
      align-items: center;
    }

    &.align-items-end {
      align-items: flex-end;
    }

    &HasError {
      color: $color-error-v2-text;
    }

    .labelText {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      // At least 100.01% is needed for FF. It's dependent on the text, but the text could break to two lines.
      width: calc(100.01% - 32px);
      hyphens: auto;

      &.lg {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
      }

      &.labelHide {
        display: none;
      }
    }

    .desktopLabel {
      padding: 2px 0;
    }
  }

  input {
    appearance: none;
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0.01;
  }

  input:focus-visible + * + .labelText {
    text-decoration: underline;
  }

  .icon {
    stroke: none;
    margin-right: 8px;
  }

  &.disabled {
    cursor: not-allowed;
    color: $color-text-tertiary;

    .mainLabel {
      cursor: not-allowed;
    }
  }
}

.formCheckbox {
  opacity: 0.0001;
}

</style>
