<template>
  <div :class="$style.rangeFilter">
    <div :class="[$style.rangeFilterControl, $style.rangeFilterMin]">
      <span :class="$style.rangeFilterControlLabel" aria-hidden="true">
        {{ $awt('aw.common.components.filters.range.from') }}
      </span>
      <aw-input-version2
        v-model.number="min"
        :special-type="inputSpecialType"
        :readonly="readOnlyInput"
        :widget-attrs="{disabled: disabledInput}"
        has-label
        :aria-label="$awt('aw.common.components.filters.range.minimum')"
      />
    </div>
    <div :class="[$style.rangeFilterControl, $style.rangeFilterMax]">
      <span :class="$style.rangeFilterControlLabel" aria-hidden="true">
        {{ $awt('aw.common.components.filters.range.to') }}
      </span>
      <aw-input-version2
        v-model.number="max"
        :special-type="inputSpecialType"
        :readonly="readOnlyInput"
        :widget-attrs="{disabled: disabledInput}"
        has-label
        :aria-label="$awt('aw.common.components.filters.range.maximum')"
      />
    </div>
    <client-only aria-hidden="true">
      <vue-slider
        v-model="computedValues"
        :min="rangeMin"
        :max="rangeMax"
        :enable-cross="false"
        tooltip="none"
        :dot-size="16"
        :data="sliderData"
        :dot-attrs="{ 'tabindex': '-1' }"
        :class="$style.rangeFilterSlider"
      />
    </client-only>
  </div>
</template>

<script>
  import { defineAsyncComponent } from 'vue';
  import AwInputVersion2 from '~~/common/components/Common/AwInputVersion2';
  import { THROTTLE_TIMEOUT } from '~~/shop/awPlugins/app-config';
  import { PUSH_RANGE_FILTER } from '~~/common/plugins/aw-analytics.js';

  export default {
    name: 'RangeFilter',
    components: {
      AwInputVersion2,
      VueSlider: defineAsyncComponent(() => import('vue-slider-component')),
    },
    props: {
      values: {
        type: Array,
        default: () => [],
      },
      rangeValues: {
        type: Array,
        default: () => [],
      },
      inputSpecialType: {
        type: String,
        default: null,
      },
      sliderData: {
        type: Array,
        default: undefined,
      },
      readOnlyInput: {
        type: Boolean,
        default: false,
      },
      disabledInput: {
        type: Boolean,
        default: false,
      },
    },
    emits: ['range-change'],
    data () {
      return {
        localValues: [],
        valueChangeThrottle: null,
      };
    },
    computed: {
      min: {
        get () {
          if (this.localValues[0] || this.localValues[0] === 0) {
            return this.localValues[0];
          } else {
            return this.rangeMin;
          }
        },
        set (newValue) {
          this.localValues[0] = newValue;
          this.$emit('range-change', { price: this.getLocalValues });
        },
      },
      max: {
        get () {
          if (this.localValues[1] || this.localValues[1] === 0) {
            return this.localValues[1];
          } else {
            return this.rangeMax;
          }
        },
        set (newValue) {
          this.localValues[1] = newValue;
          this.$emit('range-change', { price: this.getLocalValues });
        },
      },
      rangeMin () {
        return parseInt(this.rangeValues.find(value => value.name === 'min').value);
      },
      rangeMax () {
        return parseInt(this.rangeValues.find(value => value.name === 'max').value);
      },
      computedValues: {
        get () {
          return this.localValues.length ? this.localValues : [this.min, this.max];
        },
        set (newValue) {
          this.localValues = newValue;
          clearTimeout(this.valueChangeThrottle);
          this.valueChangeThrottle = setTimeout(() => {
            // todo NOTE if we change the "price" property in args, we need to change in dealer/components/Page/ProductList/Filters/CheckboxFilter.vue.handleRangeChange method too
            this.$emit('range-change', { price: this.getLocalValues });
            this.$awAnalytics[PUSH_RANGE_FILTER]({
              value0: this.getLocalValues[0],
              value1: this.getLocalValues[1],
            });
          }, THROTTLE_TIMEOUT);
        },
      },
      getLocalValues () {
        return this.localValues[0] > this.localValues[1] ? [this.localValues[1], this.localValues[0]] : this.localValues;
      },
    },
    mounted () {
      this.localValues = this.values;
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
.rangeFilter {
  display: grid;
  grid-template-columns: repeat(2, calc(50% - 4px));
  row-gap: 16px;
  column-gap: 8px;
  grid-template-areas: "min max" "slider slider";
  @include tablet(min) {
    grid-template-areas: "min min" "max max" "slider slider";
  }
  @include desktop-medium(min) {
    grid-template-areas: "min max" "slider slider";
  }

  &Min {
    grid-area: min;
  }

  &Max {
    grid-area: max;
  }

  &Slider {
    grid-area: slider;
  }

  &Control {
    position: relative;

    &Label {
      font-family: $secondary-font;
      font-size: 12px;
      font-weight: $font-weight-normal-v2;
      line-height: 16px;
      position: absolute;
      z-index: 1;
      margin-top: 8px;
      margin-left: 16px;
      color: $color-text-secondary;
    }
  }
}
</style>
