<template>
  <component
    :is="floatingLabelText ? 'label' : 'div'"
    data-input-wrapper
    :class="[
      $style.formInput,
      $style[`formInputPlaceholderFontWeight${ isEmpty ? widgetAttrs.placeholderFontWeight || 'Bold' : ''}`],
      {
        [$style.formInputDisabled]: widgetAttrs.disabled,
        [$style.formInputFocused]: isInputFocused,
        [$style.formInputEmpty]: isEmpty,
        [$style.formInputPlaceholder]: isEmpty,
        [$style.formInputError]: hasError,
        [$style.formInputLabeled]: hasLabel,
        [$style.floatingLabel]: floatingLabelText,
      },
    ]"
  >
    <span
      v-if="widgetIconAttrs.name && !isIconAfter"
      :class="[$style.formInputIcon, $style.formInputIconBefore]"
    >
      <lv-icon v-bind="widgetIconAttrs" />
    </span>
    <div
      :class="[
        $style.formInputNode,
        $style.formInputNodePrefix,
        {
          [$style.formInputNodePrefixHasLabel]: floatingLabelText || (hasLabel && !isEmpty),
          [$style.formInputNodePrefixFilled]: widgetAttrs.prefixStyling?.filled,
        },
      ]"
    >
      <!-- eslint-disable-next-line vue/singleline-html-element-content-newline -->
      <slot name="prefix">{{ widgetProps.prefix }}</slot>
    </div>
    <input
      ref="input"
      v-bind="attributes"
      :aria-invalid="isInvalid.toString()"
      :aria-required="isRequired.toString()"
      :aria-describedby="ariaDescribedby"
      :class="[
        $style.formInputNode,
        $style.formInputNodeInput,
        {
          [$style.formInputNodeHasPrefix]: widgetProps.prefix || $slots.prefix?.()?.[0]?.children?.length,
          [$style.formInputNodeHasPostfix]: widgetProps.postfix || $slots.postfix?.()?.[0]?.children?.length,
          [$style.formInputNodeInputError]: hasError,
          [$style.formInputNodeInputEmpty]: !transformedModelValue?.length,
        }
      ]"
      data-input-block
      :data-input="dataInput"
      :max="max"
      :min="min"
      :step="step"
      :type="calculatedType"
      :value="transformedModelValue"
      :size="changeSizeOnInput && calculatedSize && calculatedSize > 0 ? calculatedSize : ''"
      @blur="onBlur"
      @focus="onFocus"
      @input="onInput"
      @click="onClick"
    >
    <div
      data-input-postfix
      :class="[
        $style.formInputNode,
        $style.formInputNodePostfix,
        {
          [$style.formInputNodePostfixFilled]: widgetAttrs.postfixStyling?.filled
        }
      ]"
    >
      <!-- eslint-disable-next-line vue/singleline-html-element-content-newline -->
      <slot name="postfix">{{ widgetAttrs.postfix }}</slot>
    </div>
    <span
      v-if="floatingLabelText"
      :class="[$style.floatingLabelText, ...floatingLabelClasses]"
      v-text="floatingLabelText"
    />
    <span
      v-if="widgetIconAttrs.name && isIconAfter"
      :class="[$style.formInputIcon, $style.formInputIconAfter]"
    >
      <lv-icon v-bind="widgetIconAttrs" />
    </span>
  </component>
</template>

<script>
  import { useRuntimeConfig } from 'nuxt/app';
  import { withModifiers, nextTick } from 'vue';
  import { LvIcon } from '~~/common/components/loginet-vue-shop/index.mjs';
  import { useUserInterfaceStore } from '~~/common/stores/userInterface';
  import awListenersMixin from '~~/common/mixins/awListenersMixin.js';
  import { createValidator } from '~~/common/utils/form.js';

  const validSpecialTypes = ['price', 'price_without_currency', 'tax'];

  export default {
    name: 'AwInputVersion2',
    components: {
      LvIcon,
    },
    mixins: [awListenersMixin],
    inheritAttrs: false,
    props: {
      modelValue: {
        type: [String, Number],
        default: '',
      },
      type: {
        type: [String, Object],
        default: 'text',
      },
      specialType: {
        type: String,
        default: null,
        validator: st => validSpecialTypes.includes(st),
      },
      min: {
        type: Number,
        default: null,
      },
      max: {
        type: Number,
        default: null,
      },
      step: {
        type: Number,
        default: null,
      },
      isFocused: {
        type: Boolean,
        default: false,
      },
      widgetProps: {
        type: Object,
        default () {
          return {};
        },
      },
      widgetAttrs: {
        type: Object,
        default () {
          return {};
        },
      },
      widgetIconAttrs: {
        type: Object,
        default () {
          return {};
        },
      },
      floatingLabelClasses: {
        type: Array,
        required: false,
        default: () => [],
      },
      floatingLabelText: {
        type: String,
        required: false,
        default: '',
      },
      hasLabel: {
        type: Boolean,
        default: false,
      },
      hasError: {
        type: Boolean,
        default: false,
      },
      hasSuccess: {
        type: Boolean,
        default: false,
      },
      isRequired: {
        type: Boolean,
        default: false,
      },
      isInvalid: {
        type: Boolean,
        default: false,
      },
      /* one or more ids joined by space */
      rowUniqueId: {
        type: String,
        default: '',
      },
      dataInput: {
        type: Boolean,
        default: false,
      },
      changeSizeOnInput: {
        type: Boolean,
        default: false,
      },
    },
    emits: [
      'focus',
      'update:modelValue',
      'AwInputBlur',
      'click',
      'AwInputFocused',
    ],
    data () {
      return {
        isInputFocused: this.isFocused,
        calculatedSize: undefined,
      };
    },
    computed: {
      isEmpty () {
        return !String(this.modelValue)?.length;
      },
      isIconAfter () {
        return this.widgetIconAttrs?.position === 'after';
      },
      getCurrency () {
        return {
          HU: 'HUF',
          PL: 'PLN',
        };
      },
      ariaDescribedby () {
        const val = (
          this.widgetAttrs?.ariaDescribedby ||
          this.widgetAttrs?.['aria-describedby']
        );
        return ([
          this.rowUniqueId,
          ...(Array.isArray(val) ? val : [val]),
        ]
          .filter(x => x)
          .join(' ') || null
        );
      },
      transformedModelValue () {
        const config = useRuntimeConfig();
        if (this.specialType === 'price') {
          return this.$price({ gross: this.modelValue }, this.getCurrency[config.public.appInstance]);
        }
        if (this.specialType === 'price_without_currency') {
          return this.$price({ gross: this.modelValue }, this.getCurrency[config.public.appInstance]).replace(/[A-Za-z\u20AC\u00A3\u00A5]/g, '').trim();
        }
        return this.modelValue;
      },
      attributes () {
        return {
          ...this.widgetAttrs,
          ...this.awListeners,
        };
      },
      calculatedType () {
        if (typeof this.type === 'string') {
          return this.type;
        } else if (this.type.value) {
          return this.type.value;
        } else {
          //todo find better fallback value
          return 'text';
        }
      },
    },
    watch: {
      isFocused (value) {
        this.isInputFocused = value;
        if (value) {
          nextTick(() => {
            this.$refs.input.select();
          });
        }
      },
    },
    mounted () {
      if (document.activeElement === this.$refs.input || this.widgetAttrs.placeholder) {
        this.$emit('focus', {
          target: this.$refs.input,
        });
      }
    },
    methods: {
      onBlur ($event) {
        this.isInputFocused = false;
        if (this.specialType === 'price') {
          const price = this.$priceToNumber($event.target.value);
          return this.$emit('update:modelValue', isNaN(price) ? (this.min || 0) : price);
        } else {
          if (this.widgetProps?.onlyDigit) {
            $event.target.value = $event.target.value.replace(/\D/g, '');
            return this.$emit('AwInputBlur', $event.target.value);
          } else {
            return this.$emit('AwInputBlur', $event.target.value);
          }
        }
      },
      onInput ($event) {
        this.calculatedSize = $event.target?.value?.length - 2;
        if (this.specialType === null) {
          if (this.widgetProps?.onlyDigit) {
            $event.target.value = $event.target.value.replace(/\D/g, '');
            return this.$emit('update:modelValue', $event.target.value);
          } else {
            return this.$emit('update:modelValue', $event.target.value);
          }
        } else if (this.specialType === 'tax') {
          return this.$emit('update:modelValue', useUserInterfaceStore().hypenateTax($event.target.value));
        }
      },
      onClick () {
        return this.$emit('click');
      },
      onFocus () {
        this.isInputFocused = true;

        this.$emit('AwInputFocused', {
          target: this.$refs.input,
        });
      },
    },
  };
  export const awInputV2Props = createValidator(
    function (
      { basicValidatorProps },
      { model } = {},
    ) {
      return {
        error: basicValidatorProps.error,
        field: {
          ...basicValidatorProps.field,
          modelValue: model.value,
          'onUpdate:modelValue': model === null ? null : withModifiers((newVal) => {
            model.value = newVal;
          }, ['trim']),
        },
      };
    },
  );

</script>

<style module lang="scss" rel="stylesheet/scss">
@import "~~/common/assets/styles/_components/_floating-label.scss";

.formInput {
  font-size: 14px;
  font-weight: $font-weight-bold-v2;
  position: relative;
  display: flex;
  align-items: center;
  transition: all $animation-speed-fast $animation-timing-function;
  border: 1px solid $color-border;
  border-radius: 12px;
  background: $color-background-2;
  box-shadow: none;

  &Focused {
    border-color: $color-border-active;
    background: $color-background-4;
  }

  &Empty {
    background: $color-background-4;
  }

  &Disabled {
    border-color: $color-border-disabled;
    background: $color-background-2 !important;

    .floatingLabelText,
    .formInputNodeInput {
      opacity: 1;
      color: $color-text-tertiary;

    }
  }

  &Placeholder {
    &FontWeight {
      &Normal {
        font-weight: $font-weight-normal-v2;
      }

      &Bold {
        font-weight: $font-weight-bold-v2;
      }
    }
  }

  $p: &;

  &Error {
    color: $color-error-v2-text;
    border-color: $color-error-v2-border;
    background: $color-error-v2-background;

    #{$p}Icon,
    #{$p}NodePrefix,
    #{$p}NodePostfix,
    .floatingLabelText {
      color: $color-error-v2-text;
    }

    #{$p}NodePrefix,
    #{$p}NodePostfix {
      border-color: $color-error-v2-border;
    }
  }

  &Node {
    line-height: 20px;
    display: block;
    padding: calc(15px + var(--input-content-offset, 0px)) 15px calc(15px - var(--input-content-offset, 0px)) 15px;
    transition: padding $animation-speed-fast $animation-timing-function;
    border: none;
    border-radius: 12px;
    background: transparent;

    &HasPrefix {
      padding-left: 8px;
    }

    &Prefix,
    &Postfix {
      margin-top: calc(2 * var(--input-content-offset, 0px));
      transition: margin $animation-speed-fast $animation-timing-function;

      &:empty {
        display: none;
      }

      &Filled {
        background: $color-alto;
      }
    }

    &Prefix,
    &Postfix {
      @include font(500, 14px, null);
      color: $color-text-secondary;
    }

    &Prefix {
      padding: 0 8px 0 15px !important;
      border-right: 1px solid $color-border-disabled;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &PrefixHasLabel {
      margin: 24px -8px 8px 0;
    }

    &Postfix {
      padding-left: 4px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &Input {
      flex-grow: 1;
      min-width: 0;

      &:-webkit-autofill {
        -webkit-background-clip: text;
        -webkit-box-shadow: 0 0 0 100px transparent inset;
      }

      &Error:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 100px $color-error-background-nontransparent inset;
        -webkit-text-fill-color: $color-error-v2-text;

      }

      &Error {
        color: $color-error-v2-text;
      }

      /* Hide password reveal button in MS Edge */
      &::-ms-reveal {
        display: none;
      }
    }
  }

  &Labeled :is(&Node, input:-webkit-autofill) {
    padding: 23px 15px 7px;
  }

  &IconBefore,
  &IconAfter {
    transition: all $animation-speed-fast $animation-timing-function;
    color: $color-boulder;
  }

  &IconBefore {
    padding-left: 15px;
  }

  &IconAfter {
    padding-right: 15px;
  }
}

input[type="password"] {
  font-weight: $font-weight-bold-v2;
}

@include floatingLabel(".floatingLabel", ".formInputNodeInput", ":is(:not(.formInputNodeInputEmpty), :focus, .formInputNodeHasPrefix, .formInputNodeHasPostfix, input:-webkit-autofill)");
</style>
