<template>
  <aw-form v-model="v$" :class="$style.modal" @success="onSuccess">
    <fieldset class="awFieldsetContents">
      <legend>
        <lv-heading tag="span" level="4" version2>
          {{ $awt('aw.add_product_review.rating.legend') }}
        </lv-heading>
      </legend>
      <div :class="$style.ratingGroup">
        <div
          :class="{
            [$style.rating]: true,
            [$style.ratingError]: v$.rating.$dirty && v$.rating.$invalid,
          }"
        >
          <label v-for="starIdx in MAX_RATING" :key="starIdx" :class="$style.ratingLabel">
            <input
              v-model="rating"
              aria-required
              :aria-describedby="ratingId"
              :aria-invalid="v$.rating.$invalid"
              type="radio"
              :value="starIdx"
              :class="$style.ratingInput"
            >
            <span class="awSrOnlyAbs" v-text="starIdx" />
            <aw-star-version2
              :size="32"
              bordered
              :percent="starIdx <= rating ? 100 : 0"
              :class="{
                [$style.star]: true,
                [$style.starFull]: starIdx <= rating,
              }"
            />
          </label>
        </div>
        <aw-minimal-error :id="ratingId" :validator="v$.rating" />
      </div>
    </fieldset>
    <div :class="$style.nickNameInput">
      <aw-input-version2
        v-bind="validatorNickName.field"
        has-label
        :floating-label-text="$awt('aw.add_product_review.nick_name.label')"
      />
    </div>
    <aw-minimal-error v-bind="validatorNickName.error" />
    <aw-textarea-version2
      v-bind="validatorComment.field"
      has-label
      :floating-label-text="$awt('aw.add_product_review.comment.label')"
      :has-success="false"
      need-countdown
      :widget-attrs="{ maxLength: MAX_LENGTH_COMMENT }"
      :class="$style.comment"
    />
    <aw-minimal-error v-bind="validatorComment.error" />
    <aw-notification
      aria-live="assertive"
      :notifications="localNotis"
      :is-margin-less="!localNotis.length"
      @remove-notification="localNotis = []"
    />
  </aw-form>
</template>

<script setup>
  import { defineAsyncComponent, computed } from 'vue';
  import { useNuxtApp, useId } from 'nuxt/app';
  import { useVuelidate } from '@vuelidate/core';
  import { helpers, maxLength } from '@vuelidate/validators';
  import { awRequired } from '~~/common/utils/form.js';
  import { LvHeading } from '~~/common/components/loginet-vue-shop/index.mjs';
  import AwForm from '~~/common/components/Form/AwForm.vue';
  import AwInputVersion2, { awInputV2Props } from '~~/common/components/Common/AwInputVersion2.vue';
  import AwTextareaVersion2, { awTextareaV2Props } from '~~/common/components/Common/AwTextareaVersion2.vue';
  import AwMinimalError from '~~/common/components/Common/AwMinimalError.vue';

  const AwStarVersion2 = defineAsyncComponent(() => import('~~/common/components/Common/AwStarVersion2.vue'));
  const AwNotification = defineAsyncComponent(() => import('~~/common/components/Common/AwNotification.vue'));

  const emits = defineEmits(['call-action', 'form-success']);

  const { $awt, $logger } = useNuxtApp();
  const componentId = useId();
  const ratingId = `${componentId}_rating`;
  const nickNameId = `${componentId}_nick`;
  const commentId = `${componentId}_comment`;
  const MAX_RATING = 5;
  const MAX_LENGTH_COMMENT = 400;
  const rating = ref(0);
  const nickName = ref('');
  const comment = ref('');
  const localNotis = ref([]);
  const v$ = useVuelidate(
    {
      rating: {
        isValidRating: helpers.withMessage(
          ({ $invalid }) => ($invalid
            ? $awt('aw.add_product_review.rating.rating_required')
            : ''
          ),
          val => typeof val === 'number' && val > 0 && val < MAX_RATING + 1,
        ),
      },
      nickName: {
        required: awRequired(),
      },
      comment: {
        maxLength: maxLength(MAX_LENGTH_COMMENT),
      },
    },
    {
      rating,
      nickName,
      comment,
    },
  );
  const validatorNickName = computed(() => awInputV2Props({
    validator: v$.value,
    validatorName: 'nickName',
    model: nickName,
    id: nickNameId,
  }));
  const validatorComment = computed(() => awTextareaV2Props({
    validator: v$.value,
    validatorName: 'comment',
    model: comment,
    id: commentId,
  }));
  function onSuccess () {
    try {
      emits('form-success', {
        rating: rating.value,
        editorName: nickName.value,
        body: comment.value,
        feedBack (isSuccess, err) {
          if (isSuccess) {
            emits('call-action');
          } else if (err) {
            localNotis.value = [
              {
                uuid: 0,
                type: 'error',
                iconName: 'error-16',
                manualClose: false,
                thickContent: false,
                text: {
                  title: err?.response?.data?.message || err,
                },
              },
            ];
          }
        },
      });
    } catch (err) {
      $logger.error(err);
    }
  }
</script>

<style module lang="scss" rel="stylesheet/scss">
.modal {
  text-align: left !important;
}

.rating {
  display: flex;
  justify-content: center;
  gap: 8px;
  padding: 18px 8px;
  border-radius: 12px;
  background: $color-white;

  &Error {
    border: 1px solid $color-error-v2-border;
  }
}

.ratingGroup {
  padding-top: 12px;
  padding-bottom: 40px;

  @include tablet(min) {
    padding-top: 20px;
  }
}

.star:not(.starFull) {
  color: $color-white !important;
}

.ratingLabel {
  position: relative;
  cursor: pointer;
}

.ratingInput {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  appearance: none;
  border-radius: inherit;
  outline: 2px solid transparent;
  background: transparent;

  &:focus-visible {
    outline-color: $color-black;
  }
}

.comment {
  margin-top: 20px;
}

.nickNameInput {
  max-width: 248px;

  @include tablet(min) {
    max-width: 296px;
  }
}
</style>
