<template>
  <aw-phone-input-version2
    v-if="modelValue.version2"
    v-model="inputValue"
    :widget-attrs="modelValue.widgetAttrs"
    :has-error="hasError"
    :has-success="hasSuccess"
    :is-focused="isFocused"
    :is-expand="modelValue.isExpand"
    :is-invalid="isInvalid"
    :is-required="isRequired"
    :row-unique-id="rowUniqueId"
    @validation="onValidation"
    @blur="onBlur"
  />
  <aw-phone-input
    v-else
    v-model="inputValue"
    :widget-attrs="modelValue.widgetAttrs"
    :has-error="hasError"
    :has-success="hasSuccess"
    :is-focused="isFocused"
    :input-size="modelValue.inputSize"
    :is-expand="modelValue.isExpand"
    :is-invalid="isInvalid"
    :is-required="isRequired"
    :row-unique-id="rowUniqueId"
    @validation="onValidation"
    @blur="onBlur"
  />
</template>

<script>
  import AwPhoneInput from '../../Common/AwPhoneInput';
  import AwPhoneInputVersion2 from '../../Common/AwPhoneInputVersion2';
  import formMixin from '~~/common/mixins/formMixin';

  export default {
    name: 'AwFormPhoneInput',
    components: {
      AwPhoneInput,
      AwPhoneInputVersion2,
    },
    mixins: [formMixin],
    props: {
      isFocused: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      inputValue: {
        get () {
          return this.modelValue;
        },
        set (value) {
          this.modelValueData = value;
          if (this.blur) {
            this.validateTheRow();
          }
          this.emitData(this.modelValueData);
        },
      },
    },
    methods: {
      onValidation (/* isValid */) {
        this.emitData(this.modelValue);
      },
    },
  };
</script>
