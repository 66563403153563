<template>
  <div :class="$style.mainWrapper">
    <fieldset class="awFieldsetContents">
      <legend class="awSrOnlyAbs" v-text="$awt('aw.common.phone_input.legend')" />
      <lv-grid type="row" align-items="center" :gutter="gutter">
        <lv-grid type="item">
          <template v-if="countryCallingCodes.length > 1">
            <label>
              <span class="awSrOnlyAbs" v-text="$awt('aw.common.phone_input.labels.country')" />
              <!-- eslint-disable vue/no-mutating-props -->
              <aw-select
                v-model:selected-options="modelValue.model[0]"
                :multiple="false"
                :options="countryCallingCodes"
                :dropdown-icon-attrs="{
                  name: 'chevron-down-24',
                  size: 24
                }"
                :widget-attrs="widgetAttrs"
                :disabled="widgetAttrs.disabled"
                :input-size="inputSize"
                @update:selected-options="onCountryCodeChange"
              />
              <!-- eslint-enable vue/no-mutating-props -->
            </label>
          </template>
          <template v-else>
            <span class="awSrOnlyAbs" v-text="$awt('aw.common.phone_input.labels.country')" />
            <span :class="[$style.textFormat, $style[inputSize]]">
              {{ countryCallingCodes[0] }}
            </span>
          </template>
        </lv-grid>
        <lv-grid type="item">
          <template v-if="areaCodes.length > 1">
            <label>
              <span class="awSrOnlyAbs" v-text="$awt('aw.common.phone_input.labels.district')" />
              <!-- eslint-disable vue/no-mutating-props -->
              <aw-select
                v-model:selected-options="modelValue.model[1]"
                :multiple="false"
                :options="areaCodes"
                :dropdown-icon-attrs="{
                  name: 'chevron-down-24',
                  size: 24
                }"
                :input-size="inputSize"
                :widget-attrs="widgetAttrs"
                :disabled="widgetAttrs.disabled"
                @update:selected-options="onChange"
                @blur="$emit('blur')"
              />
              <!-- eslint-enable vue/no-mutating-props -->
            </label>
          </template>
          <template v-else-if="areaCodes.length">
            <span class="awSrOnlyAbs" v-text="$awt('aw.common.phone_input.labels.district')" />
            <span :class="[$style.textFormat, $style[inputSize]]">
              {{ areaCodes[0] }}
            </span>
          </template>
        </lv-grid>
        <lv-grid type="item" :xs="isExpand ? 'expand' : 'min'">
          <label>
            <span class="awSrOnlyAbs" v-text="$awt('aw.common.phone_input.labels.rest')" />
            <!-- eslint-disable vue/no-mutating-props -->
            <aw-input
              v-model="modelValue.model[2]"
              :maxlength="maxlength"
              :char-width="maxlength"
              :input-size="inputSize"
              :has-error="hasError"
              :has-success="hasSuccess"
              :is-focused="isFocused"
              :widget-attrs="widgetAttrs"
              :is-invalid="isInvalid"
              :is-required="isRequired"
              :row-unique-id="rowUniqueId"
              @update:model-value="onChange"
              @blur="$emit('blur')"
            />
            <!-- eslint-enable vue/no-mutating-props -->
          </label>
        </lv-grid>
      </lv-grid>
    </fieldset>
  </div>
</template>

<script>
  import { defineAsyncComponent } from 'vue';
  import { LvGrid } from '~~/common/components/loginet-vue-shop/index.mjs';
  import AwInput from '~~/common/components/Common/AwInput';
  import { useUserInterfaceStore } from '~~/common/stores/userInterface';

  export default {
    name: 'AwPhoneInput',
    components: {
      LvGrid,
      AwInput,
      AwSelect: defineAsyncComponent(() => import('~~/common/components/Common/AwSelect')),
    },
    props: {
      modelValue: {
        type: Object,
        required: true,
        validator: val => Array.isArray(val.model) && val.model.length === 3,
      },
      inputSize: {
        type: String,
        default: 'lg',
        validator: value => ['xs', 'md', 'lg'].includes(value),
      },
      gutter: {
        type: String,
        required: false,
        default: 'sm',
      },
      isExpand: {
        type: Boolean,
        required: false,
        default: false,
      },
      hasError: {
        type: Boolean,
        default: false,
      },
      hasSuccess: {
        type: Boolean,
        default: false,
      },
      isFocused: {
        type: Boolean,
        default: false,
      },
      widgetAttrs: {
        type: Object,
        default: () => ({}),
      },
      isRequired: {
        type: Boolean,
        default: false,
      },
      isInvalid: {
        type: Boolean,
        default: false,
      },
      rowUniqueId: {
        type: String,
        required: true,
      },
    },
    emits: [
      'update:modelValue',
      'blur',
      'validation',
    ],
    computed: {
      formats () {
        return useUserInterfaceStore().getValidator('phone_number').metaData;
      },
      countryCallingCodes () {
        return Object.keys(this.formats);
      },
      areaCodes () {
        return this.formats[this.modelValue.model[0]]?.areaCodes || [];
      },
      maxlength () {
        return this.formats[this.modelValue.model[0]]?.maxlength || 0;
      },
      isValid () {
        return useUserInterfaceStore().phoneValidator(this.modelValue.model);
      },
    },
    mounted () {
      if (!this.countryCallingCodes.includes(this.modelValue.model[0])) {
        // eslint-disable-next-line vue/no-mutating-props
        this.modelValue.model[0] = this.countryCallingCodes[0];
      }
      this.setDataFromCCC();
    },
    methods: {
      onCountryCodeChange () {
        this.setDataFromCCC();
      },
      onChange () {
        this.$emit('update:modelValue', this.modelValue);
        this.$emit('validation', this.isValid);
      },
      setDataFromCCC () {
        if (!this.areaCodes.includes(this.modelValue.model[1])) {
          // eslint-disable-next-line vue/no-mutating-props
          this.modelValue.model[1] = this.areaCodes[0] || '';
        }
        if (!this.isValid) {
          // eslint-disable-next-line vue/no-mutating-props
          this.modelValue.model[2] = '';
        }
        this.onChange();
      },
    },
  };
</script>
<style module lang="scss" rel="stylesheet/scss">
.mainWrapper {
  .textFormat {
    &.xs {
      font-size: $font-size-lg;
    }

    &.md {
      font-size: $font-size-md;
    }

    &.lg {
      font-size: $font-size-xs;
    }
  }

}
</style>
