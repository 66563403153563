<template>
  <div
    :class="[
      $style.componentWrapper,
      {
        [$style.componentWrapperDisabled]: widgetAttrs.disabled,
        [$style.componentWrapperLabeled]: hasLabel,
        [$style.componentWrapperFocused]: isInputFocused,
        [$style.componentWrapperError]: hasError,
        [$style.componentWrapperEmpty]: isEmpty,
        [$style.floatingLabel]: floatingLabelText,
      },
    ]"
  >
    <component :is="floatingLabelText ? 'label' : 'div'" :class="$style.labelWrapper">
      <textarea
        v-bind="attributes"
        :id="widgetAttrs.id"
        ref="input"
        v-model="localValue"
        :class="[
          $style.textarea,
          $style[modelValue.type],
          {
            [$style.textareaEmpty]: isEmpty,
          },
        ]"
        :type="modelValue.type"
        :name="modelValue.name"
        :minlength="modelValue.minLength ? modelValue.minLength : 0"
        :maxlength="maxLength"
        :aria-invalid="isInvalid.toString()"
        :aria-required="isRequired.toString()"
        :aria-describedby="rowUniqueId ? rowUniqueId : null"
        @focus="isInputFocused = true"
        @blur="onBlur"
      />
      <span v-if="floatingLabelText" :class="$style.floatingLabelText" v-text="floatingLabelText" />
    </component>
    <p v-if="needCountdown" :class="$style.countdown">
      {{ maxLength }}/{{ countdown }}
    </p>
  </div>
</template>

<script>

  import { withModifiers, nextTick } from 'vue';
  import awListenersMixin from '~~/common/mixins/awListenersMixin.js';
  import { createValidator } from '~~/common/utils/form.js';

  export default {
    name: 'AwTextareaVersion2',
    mixins: [awListenersMixin],
    props: {
      modelValue: {
        type: [String, Number],
        default: '',
      },
      inputValue: {
        type: String,
        default: '',
      },
      type: {
        type: String,
        default: 'text',
      },
      min: {
        type: Number,
        default: null,
      },
      max: {
        type: Number,
        default: null,
      },
      step: {
        type: Number,
        default: null,
      },
      isFocused: {
        type: Boolean,
        default: false,
      },
      widgetAttrs: {
        type: Object,
        default () {
          return {};
        },
      },
      widgetIconAttrs: {
        type: Object,
        default () {
          return {};
        },
      },
      floatingLabelText: {
        type: String,
        required: false,
        default: '',
      },
      hasLabel: {
        type: Boolean,
        default: false,
      },
      hasError: {
        type: Boolean,
        default: false,
      },
      hasSuccess: {
        type: Boolean,
        default: false,
      },
      charWidth: {
        type: Number,
        default: 0,
      },
      isRequired: {
        type: Boolean,
        default: false,
      },
      isInvalid: {
        type: Boolean,
        default: false,
      },
      rowUniqueId: {
        type: String,
        default: '',
      },
      needCountdown: {
        type: Boolean,
        default: false,
      },
    },
    emits: [
      'update:modelValue',
      'blur',
      'focus',
    ],
    data () {
      return {
        isInputFocused: false,
      };
    },
    computed: {
      isEmpty () {
        return !this.modelValue?.length;
      },
      localValue: {
        get () {
          return this.modelValue;
        },
        set (value) {
          this.$emit('update:modelValue', value);
        },
      },
      attributes () {
        return {
          ...this.awAttrsNoStyleNoClassNoListeners,
          ...this.widgetAttrs,
        };
      },
      minLength () {
        return this.widgetAttrs.minLength ? this.widgetAttrs.minLength : 0;
      },
      maxLength () {
        return this.widgetAttrs.maxLength ? this.widgetAttrs.maxLength : 255;
      },
      countdown () {
        if (!this.localValue) {
          return this.maxLength;
        }
        return this.maxLength - this.localValue?.length;
      },
    },
    watch: {
      isFocused (value) {
        this.isInputFocused = value;
        if (value) {
          nextTick(() => {
            this.$refs.input.select();
          });
        }
      },
      isInputFocused (value) {
        if (value) {
          this.$emit('focus', {
            target: this.$refs.input,
          });
        }
      },
    },
    mounted () {
      if (document.activeElement === this.$refs.input || this.widgetAttrs.placeholder) {
        this.$emit('focus', {
          target: this.$refs.input,
        });
      }
    },
    methods: {
      onBlur () {
        this.isInputFocused = false;
        this.$emit('blur', {
          target: this.$refs.input,
        });
      },
    },
  };
  export const awTextareaV2Props = createValidator(
    function (
      { basicValidatorProps },
      { model } = {},
    ) {
      return {
        error: basicValidatorProps.error,
        field: {
          ...basicValidatorProps.field,
          modelValue: model.value,
          'onUpdate:modelValue': model === null ? null : withModifiers((newVal) => {
            model.value = newVal;
          }, ['trim']),
        },
      };
    },
  );
</script>

<style module lang="scss" rel="stylesheet/scss">
@import "~~/common/assets/styles/_components/_floating-label.scss";

.componentWrapper {
  font-family: $secondary-font;
  font-weight: 900;
  padding: 15px;
  transition: all $animation-speed-fast $animation-timing-function;
  border: 1px solid $color-border;
  border-radius: 12px;
  background: $color-background-2;

  &Focused {
    border: 1px solid $color-border-active;
    background: $color-background-4;
  }

  &Labeled {
    padding: 23px 15px 15px;
  }

  &Empty {
    font-weight: 500;
    background: $color-background-4;
  }

  &Disabled {
    border-color: $color-border-disabled;
    background: $color-background-2 !important;

    .floatingLabelText,
    .textarea {
      color: $color-text-tertiary;
    }

    .textarea {
      opacity: 1;
    }
  }

  &Error {
    border: 1px solid $color-error-v2-border;
    background: $color-error-v2-background;

    .countdown,
    .floatingLabelText {
      color: $color-error-v2-text;
    }
  }
}

.textarea {
  display: block;
  width: 100%;
  min-height: 78px;
  max-height: 50vh;
  padding: 0; // FF has 2px padding on textarea
  transition: all $animation-speed-fast $animation-timing-function;
  border: none;
  @include font(null, 14px, 20px);

  &::placeholder {
    font-weight: 500;
    opacity: 1;
    color: $color-text-secondary;
  }

  &:-ms-input-placeholder {
    color: $color-text-secondary;
  }

  &::-ms-input-placeholder {
    color: $color-text-secondary;
  }
}

.countdown {
  padding-top: 8px;
  text-align: right;
  color: $color-text-secondary;
  @include font(500, 12px, 16px);
}

.labelWrapper {
  display: block;
}
@include floatingLabel (".floatingLabel", ".textarea", ":is(:not(.textareaEmpty), :focus)");
</style>
