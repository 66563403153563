<template>
  <component :is="floatingLabelText ? 'label' : 'div'">
    <span
      v-if="floatingLabelText"
      :class="[$style.floatingLabelText, ...floatingLabelClasses]"
      v-text="floatingLabelText"
    />
    <div
      :id="descriptionId"
      :class="[
        $style.uploadBox,
        {
          [$style.dragging]: isDragging,
          [$style.errorBox]: errorMsg || hasError,
          [$style.successBox]: !isUploading && modelValue,
          [$style.isDisabled]: widgetAttrs?.disabled,
        },
      ]"
      @dragover.prevent="setDragging(true)"
      @dragleave.prevent="setDragging(false)"
      @drop.prevent="handleDrop"
      @click="triggerFileInput"
    >
      <template v-if="isUploading">
        <div :class="$style.uploadingIcon">
          <div :class="$style.uploadingIconSpinner" />
        </div>
        <div :class="$style.uploadingText">
          {{ $awt('aw.file_uploader.uploading_label') }}
        </div>
      </template>
      <template v-else-if="modelValue">
        <div :class="$style.successIcon">
          <div :class="$style.successIconInnerFrame">
            <lv-icon name="transaction-succeeded-24" :size="24" />
          </div>
        </div>
        <div>
          {{ $awt('aw.file_uploader.uploaded_file') }}
        </div>
        <div>
          <span v-text="modelValue.name" />
          <button
            type="button"
            :class="['awFocusV', $style.deleteButton]"
            :disabled="widgetAttrs?.disabled"
            @click="resetUpload"
          >
            {{ $awt('aw.file_uploader.delete_file') }}
          </button>
        </div>
      </template>
      <div v-else-if="canBypassDeleteBtn" :class="$style.uploadMessage">
        <template v-if="errorMsg || hasError">
          <div :class="$style.errorIcon">
            <lv-icon name="error-circle-64" :size="64" />
          </div>
          <div>
            {{
              $awt(errorMsg === ERR_FILE_SIZE
                ? 'aw.file_uploader.too_large_file'
                : 'aw.file_uploader.missing_file',
              )
            }}
          </div>
          <div :class="$style.fileLabel">
            {{
              $awt(errorMsg === ERR_FILE_SIZE
                ? 'aw.file_uploader.upload_another_file'
                : 'aw.file_uploader.add_missing_file',
              )
            }}
          </div>
        </template>
        <template v-else>
          <div :class="$style.uploadIcon">
            <lv-icon name="document-24" :size="24" />
          </div>
          <div
            v-if="tokens.fileUploadLabelForMobile && !screenRange['tablet-min']"
            :class="$style.fileLabel"
          >
            {{ tokens.fileUploadLabelForMobile }}
          </div>
          <div v-else>
            <div>
              {{ $awt('aw.file_uploader.drop_file') }}
            </div>
            <div :class="$style.fileLabel">
              {{ $awt('aw.file_uploader.upload_from_computer') }}
            </div>
          </div>
        </template>
      </div>
    </div>
    <div v-if="maxFileSize" :id="fileSizeId" :class="$style.fileSizeLimitLabel">
      {{
        $awt('aw.file_uploader.max_file_size_label', {
          max_size: maxFileSize,
        })
      }}
    </div>
    <input
      :id="uuid"
      ref="fileInputEl"
      type="file"
      class="awSrOnlyAbs"
      :aria-describedby="ariaDescribedby"
      :aria-required="isRequired"
      :aria-invalid="isInvalid"
      :disabled="isUploading"
      v-bind="widgetAttrs"
      @change="handleFileUpload"
    >
  </component>
</template>

<script setup>
  import { useId } from 'nuxt/app';
  import { useUserInterfaceStore } from '~~/common/stores/userInterface';
  import { createValidator } from '~~/common/utils/form.js';
  import { LvIcon } from '~~/common/components/loginet-vue-shop/index.mjs';

  defineOptions({
    inheritAttrs: false,
  });
  const props = defineProps({
    modelValue: {
      type: Object,
      default: null,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    widgetAttrs: {
      type: Object,
      default: () => ({}),
    },
    tokens: {
      type: Object,
      default: () => ({}),
    },
    rowUniqueId: {
      type: String,
      default: '',
    },
    maxFileSize: {
      type: Number,
      default: Infinity,
    },
    isUploading: {
      type: Boolean,
      default: false,
    },
    floatingLabelClasses: {
      type: Array,
      required: false,
      default: () => [],
    },
    floatingLabelText: {
      type: String,
      required: false,
      default: '',
    },
  });
  const emit = defineEmits([
    'update:modelValue',
    'file-manual-upload',
  ]);
  const ERR_FILE_SIZE = Symbol('errFileSize');
  const uuid = useId();
  const descriptionId = useId();
  const fileSizeId = useId();
  const errorMsg = ref(false);
  const isDragging = ref(false);
  const fileInputEl = ref(null);

  const ariaDescribedby = computed(() => {
    return ([
      props.rowUniqueId,
      descriptionId,
      fileSizeId,
    ]
      .filter(x => x)
      .join(' ') || null
    );
  });
  const screenRange = computed(() => useUserInterfaceStore().mediaQueries);

  const canBypassDeleteBtn = computed(() => {
    return !props.modelValue || props.hasError || errorMsg.value;
  });

  function setDragging (newVal) {
    if (canBypassDeleteBtn.value) {
      isDragging.value = newVal;
    }
  }
  function triggerFileInput () {
    if (canBypassDeleteBtn.value) {
      fileInputEl.value?.click?.();
    }
  }
  function handleFileUpload (event) {
    event.preventDefault();
    event.stopPropagation();
    const selectedFile = event.target.files[0];
    processFile(selectedFile);
  }
  function handleDrop (event) {
    if (!canBypassDeleteBtn.value) {
      return;
    }
    event.preventDefault();
    event.stopPropagation();
    isDragging.value = false;
    const selectedFile = event.dataTransfer.files[0];
    processFile(selectedFile);
  }
  function processFile (selectedFile) {
    if (selectedFile.size > props.maxFileSize * 1024 ** 2) {
      errorMsg.value = ERR_FILE_SIZE;
    } else {
      errorMsg.value = false;
      emit('file-manual-upload', selectedFile);
      emit('update:modelValue', selectedFile);
    }
  }
  function resetUpload (event) {
    event.stopPropagation();
    emit('update:modelValue', null);
    errorMsg.value = false;
    if (fileInputEl.value) {
      fileInputEl.value.value = null;
    }
  }
</script>

<script>
  export const awFileProps = createValidator(
    function (
      { basicValidatorProps },
      { model } = {},
    ) {
      return {
        error: basicValidatorProps.error,
        field: {
          ...basicValidatorProps.field,
          modelValue: model.value,
          'onUpdate:modelValue': model === null ? null : ((newVal) => {
            model.value = newVal;
          }),
        },
      };
    },
  );
</script>

<style module lang="scss" rel="stylesheet/scss">
.floatingLabelText {
  display: block;
  margin-bottom: 8px;
}

.uploadBox,
.errorBox,
.successBox {
  position: relative;
  padding: 18px;
  cursor: pointer;
  text-align: center;
  border: 1px dashed $color-dash-separator;
  border-radius: 16px;
  background-color: $color-background-1;

  .uploadingText {
    @include mobile(min) {
      margin-bottom: 24px;
    }
  }
}

.errorBox {
  color: $color-error-v2-text;
  border-color: $color-error-v2-border;
  background-image: linear-gradient($color-strawberry-450--12, $color-strawberry-450--12), linear-gradient($color-background-3, $color-background-3);
}

.successBox {
  font-size: 16px;
  cursor: default;
  background-color: white;
}

.isDisabled {
  cursor: not-allowed !important;
  background: $color-background-2 !important;
}

.dragging {
  border-color: $color-havelock-blue;
  background-color: $color-pattens-blue;
}

.uploadMessage {
  font-size: 16px;
}

.fileLabel {
  font-weight: 700;
  cursor: pointer;
  text-decoration: underline;
}

.deleteButton {
  font-weight: 700;
  height: 24px;
  cursor: pointer;
  text-decoration: underline;
  border: none;
  background: none;
}

.uploadIcon,
.errorIcon,
.uploadingIcon,
.successIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  margin: 0 auto 20px;
  border: 1px solid $color-border-disabled;
  border-radius: 16px;
  background-color: $color-background-2;

  .successIconInnerFrame {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    border-radius: 12px;
    background-color: white;
    box-shadow: 0 2px 8px 0 rgba($color-olive-750, 0.08);
  }
}

.uploadIcon {
  color: $color-cloud;
}

.uploadingIconSpinner {
  width: 40px;
  height: 40px;
  animation: spinner-aur408 1s infinite linear;
  border-radius: 50%;
  background: radial-gradient(farthest-side, $color-olive-750 95%, rgba(0, 0, 0, 0)) 50% 1.1px/10px 10px no-repeat,
  radial-gradient(farthest-side, rgba(0, 0, 0, 0) calc(100% - 10px), $color-border-disabled 0);
}

@keyframes spinner-aur408 {
  to {
    transform: rotate(1turn);
  }
}

.errorIcon {
  font-size: 64px;
  color: $color-error-v2-text;
  border-radius: 32px;
  background-color: $color-strawberry-450--12;
}

.fileSizeLimitLabel {
  font-size: 14px;
  font-style: italic;
  line-height: 20px;
  margin-top: 12px;
  text-align: center;
  color: $color-text-secondary;
}
</style>
