<template>
  <div :id="componentId" role="tablist" :aria-labelledby="ariaLabelledby || null">
    <template v-for="({ tab, tabpanel, tabIdx, previousTabItem, nextTabItem }, id) in tabItems" :key="id">
      <slot
        :name="tab.name"
        v-bind="getTabAttrs(id, { tab, tabpanel, tabIdx, previousTabItem, nextTabItem })"
      />
    </template>
    <template v-for="({ tab, tabpanel, tabIdx }, id) in tabItems" :key="id">
      <slot
        :name="tabpanel.name"
        v-bind="getTabpanelAttrs(id, { tab, tabpanel, tabIdx })"
      />
    </template>
  </div>
</template>
<script setup>
  import { computed, useSlots } from 'vue';
  import { useId } from 'nuxt/app';

  const props = defineProps({
    selectedId: {
      type: [null, String],
      required: true,
    },
    visibleTabItems: {
      type: Object,
      default: () => ({}),
      validator (obj) {
        return !Object.values(obj).some(val => typeof val !== 'boolean');
      },
    },
    ariaLabelledby: {
      type: String,
      required: true,
    },
    allowCloseOnClick: {
      type: Boolean,
      required: true,
    },
    noDefaultValue: {
      type: Boolean,
      default: false,
    },
  });
  const emits = defineEmits(['update:selected-id']);
  const componentId = useId();
  const slots = useSlots();
  const tabItems = computed(() => {
    const visibleTabItems = props.visibleTabItems;
    let previousTabItem = null;
    let tabIdx = 0;
    return Object.keys(slots).reduce((acc, curr) => {
      const arr = curr.match(/^(tab|tabpanel)_(.*)/) || [];
      const [, type, originalId] = arr;
      // NOTE: id is changed to include string characters so the JS object
      // iteration order is unchanged.
      if (type && originalId && visibleTabItems[originalId] !== false) {
        const id = `aw_${originalId}`;
        if (!acc[id]) {
          acc[id] = {
            id,
            tabIdx: tabIdx++,
            previousTabItem,
          };
          if (previousTabItem) {
            previousTabItem.nextTabItem = acc[id];
          }
          previousTabItem = acc[id];
        }
        acc[id][type] = {
          id: `${componentId}_${type}_${originalId}`,
          name: `${type}_${originalId}`,
        };
      }
      return acc;
    }, {});
  });

  watch(tabItems, (newVal) => {
    if (!newVal[props.selectedId]) {
      if (props.noDefaultValue) {
        return;
      }
      const firstTabItem = Object.keys(newVal)[0];
      if (firstTabItem !== undefined) {
        emits('update:selected-id', firstTabItem);
      }
    }
  }, { immediate: true });

  /* NOTE: it's important to use dashes in attribute names here, because
  they are used by v-bind and it can cause unexpected attrbibute names
  because of this "feature/bug" https://github.com/vuejs/core/issues/5477 .
  Example: aria-selected behaves well, but aria-controls does not, because
  HTML elements has an ariaControls property, but no ariaSelected property.
  If a v-bind is used then vue binds the "ariacontrols" attribute w/o
  hypens. */

  function getTabAttrs (id, { tab, tabpanel, tabIdx, previousTabItem, nextTabItem }) {
    const isSelected = props.selectedId === id;
    const hasTabindex = isSelected || (props.selectedId === null && tabIdx === 0);
    return {
      isSelected,
      tabIdx,
      attrs: {
        id: tab.id,
        tabindex: hasTabindex ? '0' : '-1',
        role: 'tab',
        'aria-selected': isSelected,
        'aria-controls': tabpanel.id,
        onClick: ($event) => onClick($event, { id }),
        onKeydown: ($event) => onKeydown($event, { id, previousTabItem, nextTabItem }),
      },
    };
  }
  function focusTargetItem (targetTabItem) {
    if (targetTabItem) {
      emits('update:selected-id', targetTabItem.id);
      document.getElementById(targetTabItem.tab.id)?.focus();
    }
  }
  function getTabpanelAttrs (id, { tab, tabpanel, tabIdx }) {
    const isSelected = props.selectedId === id;
    return {
      isSelected,
      tabIdx,
      attrs: {
        id: tabpanel.id,
        tabindex: '0',
        role: 'tabpanel',
        'aria-labelledby': tab.id,
        style: isSelected ? null : 'display: none',
      },
    };
  }
  function onClick ($event, { id }) {
    if (props.selectedId === id && props.allowCloseOnClick) {
      emits('update:selected-id', null);
    } else {
      emits('update:selected-id', id);
    }
  }
  function onKeydown ($event, { id, previousTabItem, nextTabItem }) {
    let flag = false;
    switch ($event.key) {
    case 'Enter':
    case 'Space':
      {
        emits('update:selected-id', id);
      }
      break;
    case 'ArrowLeft':
    case 'End':
      {
        flag = true;
        let targetTabItem = null;
        if (previousTabItem && $event.key === 'ArrowLeft') {
          targetTabItem = previousTabItem;
        } else {
          let lastTabItem = nextTabItem;
          while (lastTabItem?.nextTabItem) {
            lastTabItem = lastTabItem.nextTabItem;
          }
          if (lastTabItem) {
            targetTabItem = lastTabItem;
          }
        }
        focusTargetItem(targetTabItem);
      }
      break;
    case 'ArrowRight':
    case 'Home':
      {
        flag = true;
        let targetTabItem = null;
        if (nextTabItem && $event.key === 'ArrowRight') {
          targetTabItem = nextTabItem;
        } else {
          let firstTabItem = previousTabItem;
          while (firstTabItem?.previousTabItem) {
            firstTabItem = firstTabItem.previousTabItem;
          }
          if (firstTabItem) {
            targetTabItem = firstTabItem;
          }
        }
        focusTargetItem(targetTabItem);
      }
      break;
    }
    if (flag) {
      $event.stopPropagation();
      $event.preventDefault();
    }
  }
</script>
